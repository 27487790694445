import React from 'react';
import PropTypes from 'prop-types';

import LazyImage from '../../reusable_components/LazyImage';

import layoutStyles from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';
import utilStyles from '../../../styles/global_ui/util.css';

import styles from './user_card.css';

// Generic user card to act as base/wrapper for all user cards.
// Standard layout - circle avatar on left, name on right
// modify as neccessary to allow customization
const UserCard = ({ className, avatarBorder, avatarMargin, avatarSize, children, postTitle, preTitle, titleColor, titleSize, user }) => (
  <div className={`${className} ${layoutStyles.flexCenterItems}`}>
    <a
      className={`${utilStyles.circle} ${layoutStyles[`marginRight${avatarMargin}`]} ${avatarBorder ? utilStyles.border : ''}`}
      href={user.url}
    >
      <LazyImage
        ratio="1:1"
        src={user.avatar_url}
        width={avatarSize}
      />
    </a>
    <div className={styles.content}>
      <span>
        {preTitle}
        <a
          className={`${typography[`body${titleSize}`]} ${typography[`link${titleColor}`]} ${typography.bold}`}
          href={user.url}
        >
          {user.name}
        </a>
        {postTitle}
      </span>
      {children}
    </div>
  </div>
);

UserCard.propTypes = {
  avatarBorder: PropTypes.bool,
  avatarMargin: PropTypes.number,
  avatarSize: PropTypes.number,
  postTitle: PropTypes.node,
  preTitle: PropTypes.node,
  titleColor: PropTypes.oneOf(['', 'Blue', 'Charcoal', 'Graphite', 'Pebble', 'White']),
  titleSize: PropTypes.oneOf(['S', 'M', 'L']),
  user: PropTypes.shape({
    avatar_url: PropTypes.string,
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
};

UserCard.defaultProps = {
  avatarBorder: true,
  avatarMargin: 10,
  avatarSize: 40,
  postTitle: null,
  preTitle: null,
  titleColor: '',
  titleSize: 'M',
};

export default UserCard;
