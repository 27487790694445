import PropTypes from 'prop-types';
import React from 'react';
import LazyImage from '../../reusable_components/LazyImage';
import UserRelationButton from '../../../server/user/relation_button';

import { formatNumberLimit4Chars, pluralize, truncateString } from '../../../utility/formatters';

import styles from './user_card_horizontal.css';

const UserCardHorizontal = ({ avatar_url, bio, id, name, stats, updateResource, user_name }) => (
  <div className={styles.root}>
    <div className={styles.topSection}>
      <a className={styles.avatarAnchor} href={`/${user_name}`}>
        <LazyImage captureScroll={true} className={styles.avatar} ratio="1:1" src={avatar_url} width={80} />
      </a>
      <div className={styles.infoContainer}>
        <strong>
          <a className={styles.name} href={`/${user_name}`}>{name}</a>
        </strong>
        <div className={styles.stats}>
          <span>{`${formatNumberLimit4Chars(stats.live_projects)} ${pluralize('Project', stats.live_projects)}`}</span>
          <span className={styles.statBreaker}>•</span>
          <span>{`${formatNumberLimit4Chars(stats.followers)} ${pluralize('Follower', stats.followers)}`}</span>
        </div>
        <div>
          <UserRelationButton
            id={id}
            onClick={(createOrDeleteBool) => updateResource(id, 'user', ['followed_users', 'records', ['id', id]], createOrDeleteBool)}
            type="followed_user_sm"
          />
        </div>
      </div>
    </div>
    {bio && bio.length > 0 && <p className={styles.bio}>{truncateString(bio, 140)}</p>}
  </div>
);

UserCardHorizontal.propTypes = {
  avatar_url: PropTypes.string.isRequired,
  bio: PropTypes.string,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  stats: PropTypes.shape({
    followers: PropTypes.number.isRequired,
    following: PropTypes.number.isRequired,
    live_projects: PropTypes.number.isRequired,
  }).isRequired,
  updateResource: PropTypes.func.isRequired,
  user_name: PropTypes.string.isRequired,
};

UserCardHorizontal.defaultProps = { bio: null };

export default UserCardHorizontal;
