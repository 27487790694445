import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Immutable from 'immutable';
import Button from '../Button';

import styles from './carousel_editor.css';

class CarouselEditor extends Component {
  constructor(props) {
    super(props);

    this.getImages = this.getImages.bind(this);
    this.handleImageUp = this.handleImageUp.bind(this);
    this.handleImageDown = this.handleImageDown.bind(this);
    this._swapIndexes = this._swapIndexes.bind(this);

    this.state = {
      images: props.images,
      indexToAnimate: null
    };

    this.timeoutUp;
    this.timeoutDown;
  }

  componentWillUnmount() {
    window.clearTimeout(this.timeoutUp);
    window.clearTimeout(this.timeoutDown);
  }

  getImages() {
    return this.state.images;
  }

  handleImageUp(index) {
    if (index !== 0) {
      const images = this._swapIndexes(this.state.images, index, index-1);
      this.setState({ images: images, indexToAnimate: index-1 });

      this.timeoutUp = setTimeout(() => { this.setState({ indexToAnimate: null }); }, 1000);
    }
  }

  handleImageDown(index) {
    if (index !== this.state.images.size-1) {
      const images = this._swapIndexes(this.state.images, index, index+1);
      this.setState({ images: images, indexToAnimate: index+1 });

      this.timeoutDown = setTimeout(() => { this.setState({ indexToAnimate: null }); }, 1000);
    }
  }

  _swapIndexes(list, i, j) {
    const itemI = list.get(i);
    const firstSwap = list.set(i, list.get(j));
    return firstSwap.set(j, itemI);
  }

  render() {
    return (
      <div className="carousel-editor">
        {
          this.state.images.map((image, index) => {
            const boxShadow = {
              WebkitBoxShadow: "1px 1px 12px rgba(150, 230, 247, 1), 3px 3px 12px rgba(0, 0, 0, 1)",
              OBoxShadow: "1px 1px 12px rgba(150, 230, 247, 1), 3px 3px 12px rgba(0, 0, 0, 1)",
              MozBozShadow: "1px 1px 12px rgba(150, 230, 247, 1), 3px 3px 12px rgba(0, 0, 0, 1)",
              msBoxShadow: "1px 1px 12px rgba(150, 230, 247, 1), 3px 3px 12px rgba(0, 0, 0, 1)",
              boxShadow: "1px 1px 12px rgba(150, 230, 247, 1), 3px 3px 12px rgba(0, 0, 0, 1)"
            };

            return (
              <div
                key={index}
                style={this.state.indexToAnimate === index ? boxShadow : {}}
                className={`${styles.imageContainer} rce-image-container`}
                >
                <div className={`${styles.order} rce-order`}>{index+1}</div>
                <div className={`${styles.imageWrapper} rce-image-wrapper`}>
                  <div
                    style={{background: `url('${image.get('url')}') center / contain no-repeat`}}
                    className={`${styles.image} rce-image`}
                    />
                </div>
                <div className={`${styles.controls} "rce-controls`}>
                  <Button
                    classList={`${styles.button} rce-button`}
                    iconStyle={{color: '#333'}}
                    icon="fa fa-arrow-up draftster-carousel-arrow-up"
                    tooltip="Move image up"
                    onClick={this.handleImageUp.bind(this, index)}
                    />
                  <Button
                    classList={`${styles.button} rce-button`}
                    iconStyle={{color: '#333'}}
                    icon="fa fa-arrow-down draftster-carousel-arrow-down"
                    tooltip="Move image down"
                    onClick={this.handleImageDown.bind(this, index)}
                    />
                </div>
              </div>
            );
          })
        }
      </div>
    );
  }
}

CarouselEditor.propTypes = {
  images: PropTypes.instanceOf(Immutable.List).isRequired
};

export default CarouselEditor;