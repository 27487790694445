import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from './Button';

export default class LegacyWidget extends Component {
  constructor(props) {
    super(props);

    this.focus = () => this.refs.widget.focus();
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.state = { hovered: false, focused: false, height: 0 }
  }

  componentDidMount() {
    this.setState({ height: this.refs.widget.offsetHeight + 20 });  // paddingTop+paddingBottom22px - border2px.
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.selection.getAnchorKey() === this.props.block.getKey()) {
      this.setState({ focused: true });
      this.focus();
    } else if(nextProps.selection.getAnchorKey() !== this.props.block.getKey() && this.state.focused) {
      this.setState({ focused: false });
    }
  }

  handleKeyDown(e) {
    e.preventDefault();

    if(e.keyCode === 38) {
      this.props.blockProps.moveSelectionTo(this.props.block, 'previous');
    } else if(e.keyCode === 40) {
      this.props.blockProps.moveSelectionTo(this.props.block, 'next');
    }
  }

  render() {
    const widgetData = this.props.contentState.getEntity(this.props.block.getEntityAt(0)).getData().widgetData;

    const widget = widgetData.get('type') === 'widget' || widgetData.get('type') === 'repo'
      ? this._composeWidget(widgetData)
      : this._composeFile(widgetData);

    return widget;
  }

  _composeWidget(widgetData) {
    const widgetTypes = {
      'parts_widget': 'Parts List',
      'bitbucket-widget': 'Bitbucket Repo',
      'github-widget': 'Github Repo',
      'old_code_widget': 'Old Code Widget',
      'default': 'this widget'
    };

    const rootStyle = {
      boxShadow: this.state.focused ? '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)' : 'none',
      border: this.state.focused ? 'none' : '2px solid #D8D8D8',
      color: this.state.focused ? 'transparent' : 'transparent',
      maxHeight: this.state.height
    };

    return (
      <div className="react-editor-widget-placeholder"
           ref="widget"
           style={rootStyle}
           contentEditable={false}
           onKeyDown={this.handleKeyDown}
           onMouseEnter={() => this.setState({ hovered: true })}
           onMouseLeave={() => this.setState({ hovered: false })}>
        {
          this.state.hovered
            ? (<Button classList="btn btn-danger"
                       icon="fa fa-trash-o fa-lg"
                       tooltip="Delete this widget"
                       onClick={() => this.props.blockProps.remove(this.props.block)}/>)
            : (<h3 contentEditable={false}>Apologies, {widgetTypes[widgetData.get('widgetType')] || widgetTypes['default']} is now deprecated in the Story section.</h3>)
        }
      </div>
    );
  }

  _composeFile(widgetData) {
    return (
      <div className="react-editor-file"
           ref="widget"
           style={{ maxHeight: this.state.height }}
           contentEditable={false}
           onMouseEnter={() => this.setState({ hovered: true })}
           onMouseLeave={() => this.setState({ hovered: false })}>
        {
          this.state.hovered
            ? (<Button classList="btn btn-danger"
                       icon="fa fa-trash-o fa-lg"
                       tooltip="Delete this file"
                       onClick={() => this.props.blockProps.remove(this.props.block)}/>)
            : (<span>
                 <i className="fa fa-file-o fa-lg"></i>
                 <a href={widgetData.get('url')}>{widgetData.get('content')}</a>
               </span>)
        }
      </div>
    );
  }
}

LegacyWidget.propTypes = {
  block: PropTypes.object.isRequired,
  blockProps: PropTypes.object.isRequired
};