import React from 'react';
import PropTypes from 'prop-types';

import FlagButton from '../../buttons/flag_button';
import MarkSpamButton from '../../buttons/mark_spam_button';

import { NOOP_HREF } from '../../../constants/links';
import menuStyles from '../../form_components/simple_select/simple_select.css';
import layout from '../../../styles/global_ui/layout.css';
import buttons from '../../../styles/global_ui/buttons.css';
import typography from '../../../styles/global_ui/typography.css';

// TODO: Remove FontAwesome and bootstrap classes. Bring in components that already do this work.
const CommentMenu = ({ comment, currentUser, onDeleteClick, onEditClick }) => {
  if (!currentUser) return null;

  const isCurrentUserCommentOwner = currentUser.id === comment.user.id;
  const canCurrentUserManage = currentUser.isAdmin || isCurrentUserCommentOwner;

  return (
    <div className="dropdown comment-manage pull-right">
      <a
        aria-expanded="true"
        aria-haspopup="true"
        className="dropdown-toggle btn btn-link btn-sm"
        data-toggle="dropdown"
        href={NOOP_HREF}
        type="button"
      >
        <i className="fa fa-ellipsis-v" />
      </a>
      <ul className="dropdown-menu">
        {canCurrentUserManage && (
          <li className={menuStyles.optionPaddingOnChild}>
            <span onClick={onEditClick}>Edit</span>
          </li>
        )}
        {canCurrentUserManage && (
          <li className={menuStyles.optionPaddingOnChild}>
            <span onClick={onDeleteClick}>Delete</span>
          </li>
        )}
        {currentUser.isAdmin && (
          <li className={menuStyles.optionPaddingOnChild}>
            <MarkSpamButton
              className={`${layout.borderNone} ${layout.fullWidth} ${buttons.white} ${typography.textLeft}`}
              flaggable={{ type: 'Comment', id: comment.id }}
            />
          </li>
        )}
        {!isCurrentUserCommentOwner && !currentUser.isAdmin && (
          <li className={menuStyles.optionPaddingOnChild}>
            <FlagButton
              currentUserId={currentUser.id}
              flaggable={{ type: 'Comment', id: comment.id }}
            />
          </li>
        )}
      </ul>
    </div>
  );
};

CommentMenu.propTypes = {
  comment: PropTypes.shape({ id: PropTypes.number.isRequired }).isRequired,
  currentUser: PropTypes.shape({
    id: PropTypes.number.isRequired,
    isAdmin: PropTypes.bool.isRequired,
  }),
  onDeleteClick: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
};

CommentMenu.defaultProps = { currentUser: null };

export default CommentMenu;
