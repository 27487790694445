import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from '../../../../client/buttons/base';

import currentUserService from '../../../../services/current_user';
import errorHandler from '../../../../services/error_handler';
import { summonLoginPanel } from '../../../../utility/dispatchers';

import layout from '../../../../styles/global_ui/layout.css';
import typography from '../../../../styles/global_ui/typography.css';

const LOGIN_PANEL_ARGS = {
  detail: {
    state: { currentPanel: 'signup' },
    source: 'news_cta',
  },
};

class NewsSignupCTA extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUserId: null,
      initialized: false,
    };
  }

  componentDidMount() {
    return currentUserService.getAsync('id')
      .then((currentUserId) => this.setState({ currentUserId, initialized: true }))
      .catch((err) => errorHandler('NewsSignupCTA', err));
  }

  render() {
    if (!this.state.initialized || this.state.currentUserId) return null;

    const { x1, x2 } = this.props.imageURLs;

    return (
      <div className={`${this.props.className} ${layout.flexJustifyCenter}`}>
        <div className={layout.fullWidth} style={{ maxWidth: 480 }}>
          <div className={`${layout.flexJustifySpaceBetween} ${layout.flexCenterItems}`}>
            <div className={`${typography.bodyL} ${layout.marginRight10} ${layout.flex1}`}>
              Get our weekly newsletter when you join Hackster.
            </div>
            <div className={`${layout.paddingLeft15} ${layout.paddingRight15}`} style={{ flex: '0 1 auto' }}>
              <img src={x1} srcSet={`${x1} 1x, ${x2} 2x`} />
            </div>
          </div>
          <Button
            className={`${layout.fullWidth} ${layout.marginTop30}`}
            onClick={() => summonLoginPanel(LOGIN_PANEL_ARGS)}
            size="lg"
          >
            Sign up
          </Button>
        </div>
      </div>
    );
  }
}

NewsSignupCTA.propTypes = {
  className: PropTypes.string,
  imageURLs: PropTypes.shape({
    x1: PropTypes.string.isRequired,
    x2: PropTypes.string.isRequired,
  }).isRequired,
};

NewsSignupCTA.defaultProps = { className: '' };

export default NewsSignupCTA;
