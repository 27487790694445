import PropTypes from 'prop-types';
import React from 'react';

import ButtonDropdown from '../../buttons/button_dropdown';
import Icon from '../../icon';

import buttonStyles from '../../../styles/global_ui/buttons.css';
import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';
import styles from './project_card.css';

// NOTE: Used in contest_card/helpers/ContestSponsorsList.

const _getLabel = (classList, teamName) => (
  <span className={classList.label}>
    {teamName || 'Multiple Authors'}
    <Icon className={`${typography.iconBaselineFix} ${layout.marginLeft5}`} name="arrow-down" size={12} />
  </span>
);

const _getListItems = (users) => users.map((user, i) => (
  <li key={i}>
    <a
      className={`${typography.bodyS} ${typography.link} ${styles.author}`}
      href={user.url}
    >
      {user.name}
    </a>
  </li>
));

const UsersMenu = ({ alignRight, alignTop, classList, teamName, users }) => (
  <ButtonDropdown
    alignRight={alignRight}
    alignTop={alignTop}
    classList={{
      button: `${typography.bodyS} ${typography.link} ${styles.authorButton} ${buttonStyles.blank}`,
      dropdown: styles.authorsContainer,
      wrapper: styles.dropdown,
    }}
    elevation={0}
    label={_getLabel(classList, teamName)}
  >
    <ul className={styles.authorsList}>
      {_getListItems(users)}
    </ul>
  </ButtonDropdown>
);

UsersMenu.propTypes = {
  alignRight: PropTypes.bool,
  alignTop: PropTypes.bool,
  classList: PropTypes.shape({ label: PropTypes.string }),
  teamName: PropTypes.string,
  users: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.string,
  })),
};

UsersMenu.defaultProps = {
  alignRight: false,
  alignTop: true,
  classList: { label: '' },
  teamName: null,
  users: [],
};

export default UsersMenu;
