import React from 'react';
import PropTypes from 'prop-types';

import UserCard from '../../../../../client/cards/user_card';
import UserRelationButton from '../../../../../server/user/relation_button';

import layout from '../../../../../styles/global_ui/layout.css';
import typography from '../../../../../styles/global_ui/typography.css';
import utilStyles from '../../../../../styles/global_ui/util.css';

const _getFollowButton = (user) => (
  <span className={layout.marginLeft15}>
    <UserRelationButton id={user.id} type="followed_user_xs" />
  </span>
);

const ArticleAuthorCredits = ({ user }) => (
  <div className={`${utilStyles.borderTop} ${layout.paddingTop45} ${layout.marginTop45}`}>
    <UserCard
      avatarMargin={30}
      avatarSize={75}
      postTitle={_getFollowButton(user)}
      titleColor="Charcoal"
      titleSize="L"
      user={user}
    >
      <div className={`${typography.bodyM} ${typography.breakWord} ${layout.marginTop10}`}>
        {user.bio}
      </div>
    </UserCard>
  </div>
);

ArticleAuthorCredits.propTypes = {
  user: PropTypes.shape({
    avatar_url: PropTypes.string,
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default ArticleAuthorCredits;
