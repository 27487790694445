/* eslint-disable no-prototype-builtins */
/* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import Breadcrumb from '../nav_components/breadcrumb';
import DialogCategoryMenu from './category_menu/DialogCategoryMenu';
import InfiniteScroll from '../reusable_components/InfiniteScroll';
import NavBar from '../../scenes/contest_page/discussion/NavBar';
import DummyPostCard from './post_card/DummyPostCard';
import PostCard from './post_card';
import PostEditor from './post_editor';
import StickyCategoryMenu from './category_menu/StickyCategoryMenu';
import Window from '../wrappers/window';

import { graphQuery, graphMutate } from '../../requests/graphql';

import { cleanPreAndPostSlashes } from '../reusable_components/Router/history';

import { addWorker, flagPostOrComment, markPostOrCommentAsSpam, removeFromWorkersState } from './transactions';
import { createComment, deleteComment, deleteCommentViaSpam, getComments, getCommentReplies, updateComment } from './transactions/comments';
import { createPost, deletePost, deletePostViaSpam, updatePost } from './transactions/posts';

import keenService from '../../services/keen/main';
import {
  getClickedLinkArgs,
  getClickedFilterArgs,
  getClickedMenuItemArgs,
} from '../../services/keen/events/eventTypeTemplates';

import createMarkdownRenderer from '../../services/markdown';
import errorHandler from '../../services/error_handler';
import smoothScroll from '../utils/smoothScroll';
import { getErrorMsg } from '../global_components/messenger/messages';
import { copyStringToClipboard } from '../../services/clipboard';
import { getInObj } from '../../utility/accessors';
import { isSignedInUser } from '../../utility/types';
import { mapifyStringQuery } from '../../utility/converters';
import { clickEventHasModifierKey } from '../../utility/events';
import { summonGlobalMessenger } from '../../utility/dispatchers';
import { windowLocationOrigin } from '../../services/window';

import { SIMPLE_PAGINATION } from '../../constants/pagination';

import layout from '../../styles/global_ui/layout.css';
import typography from '../../styles/global_ui/typography.css';
import utilStyles from '../../styles/global_ui/util.css';
import styles from '../../scenes/contest_page/discussion/contest_discussion.css';

const ADMIN_PATH = 'ANNOUNCEMENTS';
const SIDEBAR_ID = 'discussionSidebar';

// In the Editor, we don't care to create hoverable mentions, so we want to limit the logic to a "highlight" ruleset only.
const EDITOR_MARKDOWN_CONFIG = { highlightClass: typography.linkBlue, highlightChar: '@', rules: ['code', 'highlight'] };
const MARKDOWN_CONFIG = { highlightClass: typography.linkBlue, rules: ['code', 'mention'] };

class Discussion extends PureComponent {
  constructor(props) {
    super(props);

    const currentPath = cleanPreAndPostSlashes(props.currentHistoryData.pathname);

    this.state = {
      currentPath,
      currentView: this._getCurrentViewForPath(currentPath), // [default, category, singular]
      isBusy: true, // For crud requests
      isNavigating: true, // For page navigation/initial load
      offset: 0, // Increment / Decrement on create / delete for simple pagination
      pagination: SIMPLE_PAGINATION,
      pinnedPosts: [],
      records: [],
      workers: {}, // For nested busy states (i.e. fetch replies)
    };

    this.createOrUpdateComment = this.createOrUpdateComment.bind(this);
    this.createOrUpdatePost = this.createOrUpdatePost.bind(this);
    this.getCommentsForPost = this.getCommentsForPost.bind(this);
    this.getRepliesForComment = this.getRepliesForComment.bind(this);
    this.handleCopyToClipboard = this.handleCopyToClipboard.bind(this);
    this.handleNavigationForCategory = this.handleNavigationForCategory.bind(this);
    this.handleNavigationForPost = this.handleNavigationForPost.bind(this);
    this.handlePinClick = this.handlePinClick.bind(this);
    this.handleReportPostOrComment = this.handleReportPostOrComment.bind(this);
    this.handleSpamPostOrComment = this.handleSpamPostOrComment.bind(this);

    // Transactions
    this.addWorker = addWorker.bind(this);
    this.createComment = createComment.bind(this);
    this.createPost = createPost.bind(this);
    this.deleteComment = deleteComment.bind(this);
    this.deleteCommentViaSpam = deleteCommentViaSpam.bind(this);
    this.deletePost = deletePost.bind(this);
    this.deletePostViaSpam = deletePostViaSpam.bind(this);
    this.getComments = getComments.bind(this);
    this.getCommentReplies = getCommentReplies.bind(this);
    this.removeFromWorkersState = removeFromWorkersState.bind(this);
    this.updateComment = updateComment.bind(this);
    this.updatePost = updatePost.bind(this);

    this._isMounted = false;

    // Markdown services
    this._postAndCommentMarkdownService;
    this._postEditorMarkdownService;
    // Refs
    this._windower;
  }

  /**
   * Lifecycle
   */
  componentDidMount() {
    this._isMounted = true;
    this._postAndCommentMarkdownService = createMarkdownRenderer(MARKDOWN_CONFIG);
    this._postEditorMarkdownService = createMarkdownRenderer(EDITOR_MARKDOWN_CONFIG);
    this._init();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /**
   * Initializers
   */
  _init() {
    this.props.onInit();

    const scrollTarget = this.props.topAnchorId ? document.getElementById(this.props.topAnchorId) : 0;
    smoothScroll(scrollTarget, 0);

    this._fetchRecordsForCurrentView(this.state.currentView, this.state.currentPath);
  }

  /**
   * Methods
   */
  createOrUpdateComment({ comment, resolverFn }) {
    return comment.hasOwnProperty('id') ? this.updateComment({ comment, resolverFn }) : this.createComment({ comment, resolverFn });
  }

  createOrUpdatePost({ post, oldPost, failureFn, resolverFn }) {
    return post.hasOwnProperty('id') ? this.updatePost({ post, oldPost, failureFn, resolverFn }, this.props.categoryConfigs) : this.createPost({ post, failureFn, resolverFn });
  }

  getCommentsForPost(post, worker) {
    this.getComments(post, worker);
  }

  getRepliesForComment({ comment, postId, resolverFn, worker }) {
    this.getCommentReplies({ comment, postId, resolverFn, worker });
  }

  handleCopyToClipboard(metadata, type, resolverFn = () => {}) {
    switch (type) {
      case 'comment':
        resolverFn();
        keenService.reportEventWithObj(getClickedMenuItemArgs({ entity_id: metadata.commentId, entity_type: 'Comment', value: 'Copy link' }));

        return copyStringToClipboard(this._buildCommentLink(metadata));

      case 'post':
        resolverFn();
        keenService.reportEventWithObj(getClickedMenuItemArgs({ entity_id: metadata.postId, entity_type: 'FeedPost', value: 'Copy link' }));

        return copyStringToClipboard(this._buildPostLink(metadata));

      default:
        return resolverFn();
    }
  }

  handleNavigationForCategory(e, category = null, analytics = {}) {
    const isWorking = (this.state.isBusy || this.state.isNavigating);
    // The preceeding slash on the category path is important when we're nested in posts/:id. It tells history to return to the basePath.
    const toPath = category && category.hasOwnProperty('path') ? `/${category.path}` : '/';
    // Let browser handle mod keys.
    if (clickEventHasModifierKey(e)) {
      keenService.reportEventWithObj(getClickedLinkArgs({ ...analytics, href: toPath, entity_id: this.props.origin.id, entity_type: this.props.origin.type }, e));

      return;
    }
    // Stunt browser navigation.
    e.preventDefault();
    // Internally redirect when not busy.
    if (!isWorking) {
      this.props.transition({ pathname: toPath });
      keenService.reportEventWithObj(getClickedLinkArgs({ ...analytics, href: toPath, entity_id: this.props.origin.id, entity_type: this.props.origin.type }, e));
    }
  }

  handleNavigationForPost(e, postId) {
    if (clickEventHasModifierKey(e)) return;

    e.preventDefault();
    // Do nothing when busy.
    if (this.state.isBusy || this.state.isNavigating) return;

    const toPath = `/posts/${postId}`;

    if (this.props.currentHistoryData.pathname !== toPath) {
      this.props.transition({ pathname: toPath });
    }
  }

  handlePinClick(record, isPinned, callback) {
    callback();

    // Return and do nothing when a user revokes their pinned post request.
    if (this.state.pinnedPosts.length > 0 && !isPinned && window.confirm('This will replace the current pinned post. Are you sure?') === false) {
      return summonGlobalMessenger({ msg: 'Cancelled pinning post.', type: 'success' });
    }

    return isPinned ? this._unpinPost(record) : this._pinPost(record);
  }

  handleReportPostOrComment(record, type, callback) {
    this.props.setFlaggedIds(this._getUpdatedFlaggedIdState(record, type), callback); // Callback is to close the menu

    return flagPostOrComment(type, record.id, getInObj(['id'], this.props.currentUser))
      .catch((err) => this.props.setFlaggedIds(this._getRevertedFlaggedIdState(record, type)));
  }

  handleSpamPostOrComment(record, type, callback) {
    this.props.setFlaggedIds(this._getUpdatedFlaggedIdState(record, type), callback); // Callback is to close the menu

    return markPostOrCommentAsSpam(type, record.id)
      .then(() => this._resolveHandleSpamPostOrComment(record, type))
      .catch((err) => this.props.setFlaggedIds(this._getRevertedFlaggedIdState(record, type)));
  }

  /**
   * Helpers
   */
  _applySearchToHistory(search = '') {
    this.props.transition({ pathname: this.state.currentPath, search });
    this._fireNavFilterSelectAnalytics(search);
  }

  _buildCommentLink({ commentId, postId }) {
    return `${windowLocationOrigin.get()}${this.props.pathHelpers.basePath}/posts/${postId}#comment-${commentId}`;
  }

  _buildPostLink({ postId }) {
    const anchor = this.props.topAnchorId ? `#${this.props.topAnchorId}` : '';

    return `${windowLocationOrigin.get()}${this.props.pathHelpers.basePath}/posts/${postId}${anchor}`;
  }

  _fetchRecordsForCurrentView(currentView, currentPath) {
    if (currentView === 'singular') return this._getSingularPost(currentView, currentPath);
    // if user is not signed in on "my posts" tab, bypass this request because we know it will be empty
    if (this._isByCurrentUser() && !isSignedInUser(this.props.currentUser)) return this._getFakePaginatedResults();

    return this._getPaginatedPosts(currentView, currentPath);
  }

  _fetchMoreRecords() {
    return this._getPaginatedPosts(this.state.currentView, this.state.currentPath, { page: this.state.pagination.next_page });
  }

  _fireNavFilterSelectAnalytics(searchString) {
    const args = searchString.length > 0 ? ['my_activity', true] : ['all_posts', true];
    keenService.reportEventWithObj(getClickedFilterArgs(...args));
  }

  _getCategorySelectOpts() {
    if (this._isAdmin()) return this.props.selectCategories;

    return this.props.selectCategories.filter((opt) => opt.value !== ADMIN_PATH);
  }

  _getCurrentViewForPath(path) {
    if (path === '/') return 'default';

    return path.includes('posts/') ? 'singular' : 'category';
  }

  _getRevertedFlaggedIdState(record, type) {
    return type === 'feed_post'
      ? { flaggedPostIds: this.props.flaggedPostIds.filter((id) => id !== record.id) }
      : { flaggedCommentIds: this.props.flaggedCommentIds.filter((id) => id !== record.id) };
  }

  _getUpdatedFlaggedIdState(record, type) {
    return type === 'feed_post'
      ? { flaggedPostIds: this.props.flaggedPostIds.concat([record.id]) }
      : { flaggedCommentIds: this.props.flaggedCommentIds.concat([record.id]) };
  }

  _isByCurrentUser(currentView = this.state.currentView) {
    return currentView === 'default'
      && mapifyStringQuery(this.props.currentHistoryData.search)['by_current_user'] === 'true';
  }

  _isAdmin() {
    return getInObj(['role'], this.props.currentUser) === 'admin' || this.props.origin.admin_ids.includes(getInObj(['id'], this.props.currentUser));
  }

  _isMemberOrAdmin() {
    return this.props.isMember || this._isAdmin();
  }

  _shouldRenderPostEditorView() {
    // Order of these statements is important.
    if (!this._postEditorMarkdownService || (this.state.currentView === 'singular') || this._isByCurrentUser()) {
      return false;
    }

    if (this._isAdmin()) return true;

    return this.state.currentPath.toLowerCase() !== ADMIN_PATH.toLowerCase();
  }

  _safelySetState(state, cb) {
    if (!this._isMounted) return;
    this.setState(state, cb);
  }

  /**
   * Transactions (Reusable transactions are bound to the instance)
   */
  _getFakePaginatedResults() {
    return new Promise((resolve, reject) => {
      this._safelySetState({
        isBusy: false,
        isNavigating: false,
        pagination: SIMPLE_PAGINATION,
        records: [],
      });
      resolve();
    });
  }

  _getPaginatedPosts(currentView, currentPath, pagination = null) {
    return this._getPinnedPosts(currentView, pagination)
      .then((pinnedPosts) => Promise.all([
        pinnedPosts,
        graphQuery({ t: 'feed_posts_simple_pagination' }, this._getPaginatedPostsArgs(currentView, currentPath, pagination, pinnedPosts)),
      ]))
      .then(([pinnedPosts, { feed_posts }]) => {
        this._safelySetState({
          isBusy: false,
          isNavigating: false,
          pagination: feed_posts.metadata,
          pinnedPosts,
          records: pagination ? this.state.records.concat(feed_posts.records) : feed_posts.records,
        });
      })
      .catch((err) => {
        this._safelySetState({
          isBusy: false,
          isNavigating: false,
        });
        errorHandler('Discussion _getPaginatedPosts: ', err);
        summonGlobalMessenger({ msg: getErrorMsg('retrieving posts'), type: 'error' });
      });
  }

  // Excludes pinned posts on the Main default board only.
  // Its the only place that they are rendered at the top and want to prevent duplicate posts in the feed.
  _getPaginatedPostsArgs(currentView, currentPath, pagination, pinnedPosts = []) {
    const by_category = currentView === 'category' ? { by_category: currentPath.toUpperCase() } : {};
    const by_current_user = this._isByCurrentUser(currentView) ? { by_current_user: true } : {};
    const exclude_posts_ids = currentView === 'default' ? pinnedPosts.map((p) => p.id) : [];
    const page = pagination ? pagination : {};

    return {
      ...by_category,
      ...by_current_user,
      ...page,
      exclude_posts_ids,
      offset: Math.max(this.state.offset, 0),
      origin_id: this.props.origin.id,
      origin_type: this.props.origin.type,
    };
  }

  _getPinnedPosts(currentView, pagination = null) {
    return new Promise((resolve, reject) => {
      // Do not fetch when paginating or on the My Activity tab.
      if (pagination !== null || this._isByCurrentUser()) return resolve(this.state.pinnedPosts);

      // On channel navigation, we'll refetch the pinnedPosts each time in case someone recently pinned something.
      return graphQuery({ t: 'feed_posts' }, { by_pinned_for_origin: true, origin_id: this.props.origin.id, origin_type: this.props.origin.type.toUpperCase() })
        .then(({ records }) => resolve(records))
        .catch((err) => {
          // Allow pinned posts to fail silently and not throw the entire app.
          errorHandler(`_getPinnedPosts error: ${err}`);
          resolve([]);
        });
    });
  }

  _getSingularPost(currentView, currentPath) {
    // currentPath is taken from history state internally, so the id will always exist here.
    // Rails router will handle cases when url excludes the id (redirects to root).
    const id = parseInt(cleanPreAndPostSlashes(currentPath).split('/')[1]);
    // Protect against strings and NaN.
    if (!id || typeof id !== 'number') return this._getFakePaginatedResults();

    return this._getPinnedPosts(currentView)
      .then((pinnedPosts) => Promise.all([
        pinnedPosts,
        graphQuery({ t: 'feed_post' }, { id, origin_id: this.props.origin.id, origin_type: this.props.origin.type }),
      ]))
      .then(([pinnedPosts, { feed_post }]) => {
        this._safelySetState({
          isBusy: false,
          isNavigating: false,
          pagination: SIMPLE_PAGINATION,
          pinnedPosts: pinnedPosts,
          records: [feed_post],
        });
      })
      .catch((err) => {
        this._safelySetState({
          isBusy: false,
          isNavigating: false,
        });
        errorHandler('Discussion _getSingularPost: ', err);
        summonGlobalMessenger({ msg: getErrorMsg('retrieving the post'), type: 'error' });
      });
  }

  _pinPost(record) {
    const refreshCurrentList = this.state.pinnedPosts.length > 0 && this.state.currentView === 'default' && !this._isByCurrentUser();
    const pinnedPostsMemo = this.state.pinnedPosts;

    this.setState({
      isBusy: true,
      pinnedPosts: refreshCurrentList ? [] : pinnedPostsMemo, // NOTE: If we add the ability to add more than one pinned post,remove this.
    });

    return graphMutate({ t: 'update_feed_post_pinned' }, { id: record.id, origin_id: this.props.origin.id, origin_type: this.props.origin.type.toUpperCase(), pin: true })
      .then(() => {
        this.setState((state) => ({
          isBusy: false,
          pinnedPosts: [record], // NOTE: If we add the ability to add more than one pinned post, concat here instead.
          records: state.currentView === 'default' ? state.records.filter((r) => r.id !== record.id) : state.records,
        }));
        summonGlobalMessenger({ msg: 'Pinned post.', type: 'success' });

        if (refreshCurrentList) this._getPaginatedPosts(this.state.currentView, this.state.currentPath);
      })
      .catch((err) => {
        this.setState({
          isBusy: false,
          pinnedPosts: pinnedPostsMemo,
        });
        errorHandler('Discussion _unpinPost error: ', err);
        summonGlobalMessenger({ msg: 'Failed to pin post.', type: 'error' });
      });
  }

  _resolveHandleSpamPostOrComment(record, type) {
    switch (type) {
      case 'comment':
        return this.deleteCommentViaSpam(record);

      case 'feed_post':
        return this.deletePostViaSpam(record);

      default:
        return;
    }
  }

  _unpinPost(record) {
    const refreshCurrentList = this.state.currentView === 'default' && !this._isByCurrentUser();

    this.setState((state) => ({
      isBusy: true,
      pinnedPosts: state.pinnedPosts.filter((p) => p.id !== record.id),
    }));

    return graphMutate({ t: 'update_feed_post_pinned' }, { id: record.id, origin_id: this.props.origin.id, origin_type: this.props.origin.type.toUpperCase(), pin: false })
      .then(() => {
        this.setState((state) => ({ isBusy: false }));
        summonGlobalMessenger({ msg: 'Unpinned post.', type: 'success' });

        if (refreshCurrentList) this._getPaginatedPosts(this.state.currentView, this.state.currentPath);
      })
      .catch((err) => {
        this.setState({
          isBusy: false,
          pinnedPosts: [record], // NOTE: If we add the ability to add more than one pinned post, concat here instead.
        });
        errorHandler('Discussion _unpinPost error: ', err);
        summonGlobalMessenger({ msg: 'Failed to unpin post.', type: 'error' });
      });
  }

  /**
   * Views
   */
  _getBreadCrumb() {
    const anchor = this.props.topAnchorId ? `#${this.props.topAnchorId}` : '';

    return (
      <Breadcrumb
        classList={{ root: layout.marginBottom10 }}
        color="Pebble"
        href={`${this.props.pathHelpers.basePath}/${anchor}`}
        onClick={(e) => this.handleNavigationForCategory(e, null, { location: 'Breadcrumb' })}
        text="Back to discussion board"
      />
    );
  }

  _getHeader() {
    switch (this.state.currentView) {
      case 'category':
        return this._getCategoryHeaderView();

      case 'default':
        return this._getDefaultHeaderView();

      case 'singular':
        return this._getSingularPostHeaderView();

      default:
        return null;
    }
  }

  _getDefaultHeaderView() {
    return (
      <div>
        {this._getHeaderTitleWithMobileMenu(<h2 className={typography.h2}>Discussion board</h2>)}
        <NavBar currentHistoryData={this.props.currentHistoryData} onClick={(opt) => this._applySearchToHistory(opt.value)} />
      </div>
    );
  }

  _getCategoryTitle(config) {
    return (
      <div className={`${layout.flexCenterItems}`}>
        <div className={`${utilStyles[config.colorClass]} ${layout.marginRight10}`} style={{ borderRadius: 2, height: 15, marginTop: 3, width: 15 }} />
        <h2 className={typography.h2}>{config.title}</h2>
      </div>
    );
  }

  _getCategoryHeaderView() {
    const config = this.props.categoryConfigs.find((config) => config.path === this.state.currentPath);
    if (!config) return null;

    return (
      <div>
        {this._getBreadCrumb()}
        {this._getHeaderTitleWithMobileMenu(this._getCategoryTitle(config))}
      </div>
    );
  }

  _getHeaderTitleWithMobileMenu(children) {
    return (
      <div className={`${layout.flexCenterItems} ${layout.flexJustifySpaceBetween} ${layout.marginBottom30}`}>
        <Fragment>
          {children}
          {this._getMobileMenu()}
        </Fragment>
      </div>
    );
  }

  _getMobileMenu() {
    return (
      <div className={layout.hiddenMedUp}>
        <DialogCategoryMenu
          basePath={this.props.pathHelpers.basePath}
          categories={this.props.categoryConfigs}
          onClick={this.handleNavigationForCategory}
          topAnchorId={this.props.topAnchorId}
        />
      </div>
    );
  }

  _getPlaceHolderView(copy) {
    return (<div className={`${typography.bodyM} ${layout.marginTop30}`}>{copy}</div>);
  }

  _getPostCreatorView() {
    if (!this._shouldRenderPostEditorView()) return null;
    if (!this._isMemberOrAdmin()) {
      return this._getPlaceHolderView(this.props.isNotMemberPlaceholder);
    }

    return (
      <PostEditor
        categoryConfig={this.props.categoryConfigs}
        categorySelectOpts={this._getCategorySelectOpts()}
        currentPath={this.state.currentPath}
        currentUser={this.props.currentUser}
        isBusy={this.state.isBusy}
        markdownService={this._postEditorMarkdownService}
        onPostOrUpdate={this.createOrUpdatePost}
        origin={this.props.origin}
      />
    );
  }

  _getSingularPostHeaderView() {
    return this._getHeaderTitleWithMobileMenu(this._getBreadCrumb());
  }

  _getPinnedPostsView() {
    if (!this.state.pinnedPosts.length) return null;
    if (this.state.currentView !== 'default' || this._isByCurrentUser()) return null;

    return (
      <div>
        {this.state.pinnedPosts.map((record) => (
          <PostCard
            key={record.id}
            basePath={this.props.pathHelpers.basePath}
            categoryConfig={this.props.categoryConfigs}
            categorySelectOpts={this._getCategorySelectOpts()}
            config={{ allowScrollToCommentOnMount: false }}
            createOrUpdateComment={this.createOrUpdateComment}
            currentPath={this.state.currentPath}
            currentUser={this.props.currentUser}
            deleteComment={this.deleteComment}
            flaggedIds={{
              comments: this.props.flaggedCommentIds,
              posts: this.props.flaggedPostIds,
            }}
            isBusy={this.state.isBusy}
            isPinned={true}
            markdownServices={{
              editor: this._postEditorMarkdownService,
              postOrComment: this._postAndCommentMarkdownService,
            }}
            onCategoryTagClick={(e, category) => this.handleNavigationForCategory(e, category, { location: 'CategoryTag' })}
            onCopyLinkClick={this.handleCopyToClipboard}
            onCreatedAtClick={this.handleNavigationForPost}
            onDeleteClick={this.deletePost}
            onPinClick={this.handlePinClick}
            onPostUpdate={this.createOrUpdatePost}
            onReportClick={this.handleReportPostOrComment}
            onShowMoreCommentRepliesClick={this.getRepliesForComment}
            onShowPreviousCommentsClick={this.getCommentsForPost}
            onSpamClick={this.handleSpamPostOrComment}
            origin={this.props.origin}
            post={record}
            userCanComment={this._isMemberOrAdmin()}
            workers={this.state.workers}
          />
        ))}
      </div>
    );
  }

  _getPosts() {
    if (this.state.isNavigating) return <DummyPostCard />;

    switch (this.state.currentView) {
      case 'default':
      case 'category':
        return this._getPostsView();

      case 'singular':
        return this._getSingularPostView();

      default:
        return null;
    }
  }

  _getPostsView() {
    if (this._isByCurrentUser() && !isSignedInUser(this.props.currentUser)) {
      return this._getPlaceHolderView('Please log in to see your posts.');
    }

    if (!this.state.records.length) return this._getPlaceHolderView('There are no posts yet!');

    const recordsCount = this.state.records.length;
    const totalRecordsCount = this.state.pagination.next_page ? (recordsCount + 1) : recordsCount;

    return (
      <InfiniteScroll
        buffer={500}
        fetchMore={() => this._fetchMoreRecords()}
        recordsCount={recordsCount}
        renderLoader={() => <DummyPostCard />}
        totalRecordsCount={totalRecordsCount}
      >
        <Window
          ref={(el) => this._windower = el}
          isBusy={this.state.isBusy}
          records={this.state.records}
        >
          {({ record }) => (
            <PostCard
              key={record.id}
              basePath={this.props.pathHelpers.basePath}
              categoryConfig={this.props.categoryConfigs}
              categorySelectOpts={this._getCategorySelectOpts()}
              config={{ allowScrollToCommentOnMount: false }}
              createOrUpdateComment={this.createOrUpdateComment}
              currentPath={this.state.currentPath}
              currentUser={this.props.currentUser}
              deleteComment={this.deleteComment}
              flaggedIds={{
                comments: this.props.flaggedCommentIds,
                posts: this.props.flaggedPostIds,
              }}
              isBusy={this.state.isBusy}
              isPinned={(this.state.pinnedPosts.findIndex((p) => p.id === record.id) !== -1)}
              markdownServices={{
                editor: this._postEditorMarkdownService,
                postOrComment: this._postAndCommentMarkdownService,
              }}
              onCategoryTagClick={(e, category) => this.handleNavigationForCategory(e, category, { location: 'CategoryTag' })}
              onCopyLinkClick={this.handleCopyToClipboard}
              onCreatedAtClick={this.handleNavigationForPost}
              onDeleteClick={this.deletePost}
              onPinClick={this.handlePinClick}
              onPostUpdate={this.createOrUpdatePost}
              onReportClick={this.handleReportPostOrComment}
              onShowMoreCommentRepliesClick={this.getRepliesForComment}
              onShowPreviousCommentsClick={this.getCommentsForPost}
              onSpamClick={this.handleSpamPostOrComment}
              origin={this.props.origin}
              post={record}
              userCanComment={this._isMemberOrAdmin()}
              workers={this.state.workers}
            />
          )}
        </Window>
      </InfiniteScroll>
    );
  }

  _getSingularPostView() {
    const post = this.state.records[0] || null;

    return (
      <PostCard
        basePath={this.props.pathHelpers.basePath}
        categoryConfig={this.props.categoryConfigs}
        categorySelectOpts={this._getCategorySelectOpts()}
        config={{ allowScrollToCommentOnMount: true }}
        createOrUpdateComment={this.createOrUpdateComment}
        currentPath={this.state.currentPath}
        currentUser={this.props.currentUser}
        deleteComment={this.deleteComment}
        flaggedIds={{
          comments: this.props.flaggedCommentIds,
          posts: this.props.flaggedPostIds,
        }}
        isBusy={this.state.isBusy}
        isPinned={(this.state.pinnedPosts.findIndex((p) => p.id === getInObj(['id'], post)) !== -1)}
        markdownServices={{
          editor: this._postEditorMarkdownService,
          postOrComment: this._postAndCommentMarkdownService,
        }}
        onCategoryTagClick={this.handleNavigationForCategory}
        onCopyLinkClick={this.handleCopyToClipboard}
        onCreatedAtClick={this.handleNavigationForPost}
        onDeleteClick={this.deletePost}
        onPinClick={this.handlePinClick}
        onPostUpdate={this.createOrUpdatePost}
        onReportClick={this.handleReportPostOrComment}
        onShowMoreCommentRepliesClick={this.getRepliesForComment}
        onShowPreviousCommentsClick={this.getCommentsForPost}
        onSpamClick={this.handleSpamPostOrComment}
        origin={this.props.origin}
        post={post}
        renderAllComments={true}
        userCanComment={this._isMemberOrAdmin()}
        workers={this.state.workers}
      />
    );
  }

  render() {
    return (
      <div className={`${layout.container} ${styles.container}`}>
        <div className={`${layout.wrapper960} ${layout.flex} ${utilStyles.posRelative}`}>
          <div className={layout.fullWidth}>
            <div className={styles.header}>{this._getHeader()}</div>
            {this._getPostCreatorView()}
            {this._getPinnedPostsView()}
            {this._getPosts()}
          </div>
          <div className={`${styles.sidebarWrapper} ${layout.hiddenMedDown}`} id={SIDEBAR_ID}>
            <StickyCategoryMenu
              basePath={this.props.pathHelpers.basePath}
              categories={this.props.categoryConfigs}
              className={styles.sidebar}
              onClick={this.handleNavigationForCategory}
              parentId={SIDEBAR_ID}
              topAnchorId={this.props.topAnchorId}
              topBuffer={this.props.stickyBuffer}
            />
          </div>
        </div>
      </div>
    );
  }
}

Discussion.propTypes = {
  categoryConfigs: PropTypes.arrayOf(PropTypes.shape({
    colorClass: PropTypes.string.isRequired,
    enum: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  })).isRequired,
  currentHistoryData: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  currentUser: PropTypes.shape({
    avatar_url: PropTypes.string.isRequired,
    confirmed: PropTypes.bool,
    id: PropTypes.number,
    name: PropTypes.string,
    role: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  flaggedCommentIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  flaggedPostIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  isMember: PropTypes.bool.isRequired,
  isNotMemberPlaceholder: PropTypes.string,
  onInit: PropTypes.func,
  origin: PropTypes.shape({
    admin_ids: PropTypes.arrayOf(PropTypes.number).isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  pathHelpers: PropTypes.shape({ basePath: PropTypes.string.isRequired }).isRequired,
  selectCategories: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    labelText: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  setFlaggedIds: PropTypes.func.isRequired,
  stickyBuffer: PropTypes.number,
  topAnchorId: PropTypes.string,
  transition: PropTypes.func.isRequired,
};

Discussion.defaultProps = {
  isNotMemberPlaceholder: 'Only participants can post here.',
  onInit: () => {},
  stickyBuffer: 0,
  topAnchorId: null,
};

export default Discussion;
