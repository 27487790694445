import React from 'react';
import PropTypes from 'prop-types';

import { getInObj } from '../../../utility/accessors';
import styles from '../post_card/post_card.css';

const shouldRenderAdminBadge = (origin, record) => {
  if (record.user.isAdmin) return true;

  const adminIds = getInObj(['admin_ids'], origin);

  return adminIds && adminIds.includes(record.user.id);
};

const AdminBadge = ({ origin, record }) => {
  if (!shouldRenderAdminBadge(origin, record)) return null;

  return (
    <div className={styles.adminBadge}>admin</div>
  );
};

AdminBadge.propTypes = {
  origin: PropTypes.shape({ admin_ids: PropTypes.arrayOf(PropTypes.number) }),
  record: PropTypes.shape({
    user: PropTypes.shape({
      id: PropTypes.number.isRequired,
      isAdmin: PropTypes.bool, // Available when record is a comment or post
    }).isRequired,
  }).isRequired,
};

export default AdminBadge;
