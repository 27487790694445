/* eslint-disable no-prototype-builtins */
/* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import deepEqual from 'deep-equal';

import BasicFormInput from '../../../../form_components/inputs/basic_form_input';
import Category from './Category';
import Checkbox from '../../../../form_components/checkboxes/custom';
import DragAndDropList from '../../../../reusable_components/DnDKit/DragAndDropList';
import EnterableChecklist from './EnterableChecklist';
import Menu from './Menu';
import QuestionMarkTooltip from '../../../../tooltips/question_mark';
import RadioGroup from '../../../../form_components/inputs/radio_group';
import SortablePrizeRow from './SortablePrizeRow';

import errorHandler from '../../../../../services/error_handler';
import smoothScroll from '../../../../utils/smoothScroll';
import { graphMutate } from '../../../../../requests/graphql';
import { doesRecordsMatch } from '../../../../../utility/predicates';
import { isBlank } from '../../../../../utility/types';
import { getIdOrUuid } from '../../../../../utility/accessors';
import { removeFromObject } from '../../../../../utility/filters';

import scrollToFirstElement from './helpers/scrollToFirstElement';
import validations from './helpers/validations';
import { CATEGORY_PRIZES_MODE, RANKED_PRIZES_MODE, CATEGORY_RANKED, RANKED_DEFAULT, CATEGORY_CUSTOM, RUNNER_UPS } from './helpers/constants';
import { CATEGORIES_PRIZES_MODE_SUB_COPY, CATEGORY_PRIZES_RADIO_COPY, DEFAULT_PRIZES_RADIO_COPY } from './helpers/copy';
import { createRankedCategory, createSinglePrizeCategory, getDefaultIntroduction, getDefaultTitleForHeader, getCustomTitleForHeader, initCategoryFromProps } from './helpers/templates';
import { toggleProcessingOverlay, toggleSubmitButton, triggerMessenger } from '../helpers';

import layout from '../../../../../styles/global_ui/layout.css';
import typography from '../../../../../styles/global_ui/typography.css';
import styles from './prize_editor.css';

const createRadioLabel = (label, tooltip) => (
  <span className={layout.flexCenterItems} style={{ lineHeight: 1 }}>
    <span className={layout.marginRight5}>{label}</span>
    <span className={typography.iconBaselineFix}><QuestionMarkTooltip offsetX={5} tooltip={tooltip} /></span>
  </span>
);
const GROUP_TYPE_RADIO_BUTTONS = [
  {
    label: createRadioLabel('Default prizes', DEFAULT_PRIZES_RADIO_COPY),
    value: RANKED_PRIZES_MODE,
  },
  {
    label: createRadioLabel('Category prizes', CATEGORY_PRIZES_RADIO_COPY),
    value: CATEGORY_PRIZES_MODE,
  },
];

const CATEGORY_LIST_ID = 'categoryPrizeList';
const PE_CONTAINER_SELECTOR = '.pe-container';
const PE_CLICKED_EVENT_NAME = 'clicked:nav';
const PE_SUBMIT_BTN_SELECTOR = '.react-prizes-submit-btn';
const HASH_LOCATION = '#prizes';

class ChallengePrizeEditor extends Component {
  constructor(props) {
    super(props);

    const categories = this._initCategories(props.challenge.prize_categories);
    const mode = this._initMode(categories);
    const store = {
      [CATEGORY_PRIZES_MODE]: mode === CATEGORY_PRIZES_MODE && categories.length > 0 ? categories : [],
      [RANKED_PRIZES_MODE]: mode === RANKED_PRIZES_MODE && categories.length > 0 ? categories : [createRankedCategory(RANKED_DEFAULT)],
    };
    const intro = (props.challenge.prizes_intro || '');
    const useCustomIntro = intro.length > 0;

    this.state = {
      customIntro: intro,
      errors: {},
      lastUseCustomIntroSaveState: useCustomIntro,
      lastIntroSaveState: intro,
      lastSaveState: store,
      mode: mode,
      sortMode: false,
      store: store,
      useCustomIntro: useCustomIntro,
    };

    this.addCategory = this.addCategory.bind(this);
    this.deleteCategory = this.deleteCategory.bind(this);
    this.handleBeforeUnloadEvent = this.handleBeforeUnloadEvent.bind(this);
    this.handlePeClickedEvent = this.handlePeClickedEvent.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onSortModeCheckboxChange = this.onSortModeCheckboxChange.bind(this);
    this.updateCategory = this.updateCategory.bind(this);
    this.updateCategoryOrder = this.updateCategoryOrder.bind(this);
  }

  /**
   * Lifecycle
   */
  componentDidMount() {
    this._listenToSubmitBtn();
    this._listenToWindowEvents();
  }

  /**
   * Initializers
   */
  _initCategories(categories) {
    return categories.map((c) => initCategoryFromProps(c));
  }

  /**
   * Infer if categories is less than three items and one of the categories has a blank name,
   * its a default prizes type. Runner ups is allowed in the default type and has a required name.
   * Custom categories all require names.
   */
  _initMode(categories) {
    // TODO: figure out what this logic _should_ be and wrap in parens to make more clear
    // eslint-disable-next-line @stylistic/no-mixed-operators
    if (!categories || categories && !categories.length) return RANKED_PRIZES_MODE;
    if (categories.length > 2) return CATEGORY_PRIZES_MODE;

    return categories.findIndex((c) => c.is_ranked && isBlank(c.name)) !== -1 ? RANKED_PRIZES_MODE : CATEGORY_PRIZES_MODE;
  }

  _listenToSubmitBtn() {
    const btn = document.querySelector(PE_SUBMIT_BTN_SELECTOR);
    if (btn) btn.addEventListener('click', this.handleSubmit);
  }

  _listenToWindowEvents() {
    window.addEventListener(PE_CLICKED_EVENT_NAME, this.handlePeClickedEvent);
    window.addEventListener('beforeunload', this.handleBeforeUnloadEvent);
  }

  /**
   * Methods
   */
  addCategory(type) {
    const currentBucket = this.state.store[this.state.mode];
    const bucket = currentBucket.concat(this._createNewCategory(type, currentBucket));
    this.setState({ store: { ...this.state.store, [this.state.mode]: bucket } });
  }

  deleteCategory(category) {
    this.setState({ store: this._deleteCategoryFromStore(category) });
  }

  handleBeforeUnloadEvent(e) {
    if (!this._isCurrentCategoryEqualToLastSaveState()) {
      const message = 'There are unsaved changes.';
      (e || window.event).returnValue = message;

      return message;
    }
  }

  handlePeClickedEvent(e) {
    if (e.detail.hash === HASH_LOCATION && !this._isCurrentCategoryEqualToLastSaveState()) {
      e.detail.event.preventDefault();

      const c = window.confirm('There are unsaved changes\nAre you sure you want to move away?');
      if (c) {
        this.setState({
          customIntro: this.state.lastIntroSaveState,
          store: this.state.lastSaveState,
          useCustomIntro: this.state.lastUseCustomIntroSaveState,
        }, () => {
          e.detail.event.target.click();
        });
      }
    }
  }

  handleSubmit() {
    const validated = this._validate();

    if (validated && !this._isCurrentCategoryEqualToLastSaveState()) {
      this._submitCurrentStore();
    } else if (validated) {
      triggerMessenger({ msg: "Nothing to save. You're all caught up!" });
    }
  }

  onSortModeCheckboxChange(sortMode) {
    this.setState({ sortMode }, () => this._scrollToCategoryList(sortMode));
  }

  updateCategory(category, errors = {}) {
    const id = getIdOrUuid(category);
    const allErrors = Object.keys(errors).length > 0
      ? { ...this.state.errors, [id]: errors }
      : removeFromObject(this.state.errors, id.toString());

    this.setState({
      errors: allErrors,
      store: this._updateRecordInStore(category),
    });
  }

  updateCategoryOrder(categories) {
    this.setState({
      store:
      {
        ...this.state.store,
        [this.state.mode]: categories.map((c, i) => ({
          ...c,
          position: i,
        })),
      },
    });
  }

  /**
   * Helpers
   */
  _canDeleteCategory(category) {
    if (category.type === RANKED_DEFAULT) return false;

    return true;
  }

  _createNewCategory(type, currentBucket) {
    const position = currentBucket.length;

    return this.state.mode === RANKED_PRIZES_MODE ? this._createNewCategoryForDefaultMode(position) : this._createNewCategoryForCategoryMode(type, position);
  }

  _createNewCategoryForCategoryMode(type, position) {
    switch (type) {
      case RUNNER_UPS:
        return createSinglePrizeCategory(true, position);

      case CATEGORY_CUSTOM:
        return createSinglePrizeCategory(false, position);

      default:
        return createRankedCategory(CATEGORY_RANKED, position);
    }
  }

  _createNewCategoryForDefaultMode(position) {
    return createSinglePrizeCategory(true, position);
  }

  _deleteCategoryFromStore(category) {
    const bucket = this.state.store[this.state.mode].filter((cat) => !doesRecordsMatch(cat, category));

    return { ...this.state.store, [this.state.mode]: bucket };
  }

  _findRecordByPositionAndName(record, position, list) {
    return list.find((item, i) => (
      ((item.hasOwnProperty('position') ? item.position : i) === position)
      && (record.name === item.name)
    ));
  }

  _getCurrentStore() {
    return this.state.store[this.state.mode];
  }

  _getEnterableCategories() {
    const typeBlacklist = [RUNNER_UPS, RANKED_DEFAULT];

    return this._getCurrentStore().filter((c) => !typeBlacklist.includes(c.type) && !isBlank(c.name)).sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;

      return 0;
    });
  }

  _getErrorsForCategory(category) {
    const id = getIdOrUuid(category);

    return this.state.errors && this.state.errors.hasOwnProperty(id) ? this.state.errors[id] : {};
  }

  _getPrizeDataForIntroduction() {
    return this._getCurrentStore().reduce((acc, category) => {
      category.prizes.forEach((prize) => {
        if (prize.cash_value) {
          acc.totalCash += parseInt(prize.cash_value);
        }
        acc.totalPrizes += 1;
      });

      return acc;
    }, { totalCash: 0, totalPrizes: 0 });
  }

  _getTitleForCategory(category) {
    if (!isBlank(category.name)) return category.name;

    return this.state.mode === RANKED_PRIZES_MODE ? getDefaultTitleForHeader(category.type) : getCustomTitleForHeader(category.type);
  }

  _isCurrentCategoryEqualToLastSaveState() {
    return (
      (this.state.useCustomIntro === this.state.lastUseCustomIntroSaveState)
      && (this.state.customIntro === this.state.lastIntroSaveState)
      && deepEqual(this._getCurrentStore(), this.state.lastSaveState[this.state.mode])
    );
  }

  _scrollToCategoryList(shouldScroll) {
    if (shouldScroll) {
      const el = document.getElementById(CATEGORY_LIST_ID);
      if (el && (el.getBoundingClientRect().top < 0)) smoothScroll(el, 200, null);
    }
  }

  _submitCurrentStore() {
    const intro = this.state.useCustomIntro ? this.state.customIntro : null;
    const challenge = this._translateChallengeForPost(intro, this._getCurrentStore());

    this._toggleFormElements(true);

    return graphMutate({ t: 'update_challenge_prizes' }, { ...challenge })
      .then((res) => {
        const updatedStore = this._updateStorePostSave(res.challenge.prize_categories, this._getCurrentStore());
        const store = { ...this.state.store, [this.state.mode]: updatedStore };

        this.setState({
          lastIntroSaveState: this.state.customIntro,
          lastSaveState: store,
          lastUseCustomIntroSaveState: this.state.useCustomIntro,
          store,
        }, () => this._submitResolver());
      })
      .catch((err) => {
        this._submitResolver({ msg: 'Changes did not save. Please check for errors and try again.', type: 'error' });
        errorHandler('ChallengePrizeEditor _submitCurrentStore', err);
      });
  }

  _submitResolver(msg = {}) {
    this._toggleFormElements(false);
    triggerMessenger(msg);
  }

  _toggleMode(e) {
    const mode = e.target.value;
    // Protect against rogue jQuery mutations (it can erase the radio values).
    if (![RANKED_PRIZES_MODE, CATEGORY_PRIZES_MODE].includes(mode)) {
      return;
    }

    this.setState({ sortMode: false, mode });
  }

  _toggleFormElements(apply) {
    toggleProcessingOverlay(apply, PE_CONTAINER_SELECTOR);
    toggleSubmitButton(apply, PE_SUBMIT_BTN_SELECTOR);
  }

  _translateChallengeForPost(intro, currentStore) {
    const prizes_intro = intro && intro.length > 0 ? intro : null;
    const prize_categories = currentStore.map((cat) => this._translateCategoryForRequest(cat));

    return {
      id: this.props.challenge.id,
      prizes_intro,
      prize_categories,
    };
  }

  _translateCategoryForRequest(category) {
    const CATEGORY_SUBMIT_BLACKLIST = ['isCollapsed', 'type', 'uuid'];

    return Object.keys(category).reduce((acc, key) => {
      if (CATEGORY_SUBMIT_BLACKLIST.includes(key)) return acc;

      if (key === 'prizes') {
        acc['prizes'] = category.prizes.map((p) => this._translatePrizeForRequest(p));
      } else if (this.state.mode === RANKED_PRIZES_MODE && key === 'name' && category.type === RANKED_DEFAULT) {
        acc['name'] = null;
      } else {
        acc[key] = category[key];
      }

      return acc;
    }, {});
  }

  _translatePrizeForRequest(prize) {
    const PRIZE_SUBMIT_BLACKLIST = ['uuid'];

    return Object.keys(prize).reduce((acc, key) => {
      if (PRIZE_SUBMIT_BLACKLIST.includes(key)) return acc;

      if (key === 'image') {
        acc['image_id'] = (!isBlank(prize.image) && !isBlank(prize.image.id)) ? prize.image.id : null;
      } else {
        acc[key] = prize[key];
      }

      return acc;
    }, {});
  }

  _updateRecordInStore(category) {
    const bucket = this.state.store[this.state.mode].map((cat) => doesRecordsMatch(cat, category) ? category : cat);

    return { ...this.state.store, [this.state.mode]: bucket };
  }

  _updateStorePostSave(categoriesFromServer, currentStore) {
    return currentStore.map((category, i) => {
      if (category.hasOwnProperty('id') && !isBlank(category.id)) return category;

      const categoryFromServer = this._findRecordByPositionAndName(category, i, categoriesFromServer);
      if (!categoryFromServer) return category;

      return {
        ...category,
        id: categoryFromServer.id,
        prizes: category.prizes.map((prize) => {
          const prizeFromServer = this._findRecordByPositionAndName(prize, prize.position, categoryFromServer.prizes);

          return !prizeFromServer ? prize : { ...prize, id: prizeFromServer.id };
        }),
      };
    });
  }

  _validate() {
    const { errors, hasErrors } = validations(this._getCurrentStore());

    if (hasErrors) {
      this.setState({ errors }, () => scrollToFirstElement(errors, this._getCurrentStore()));

      return false;
    }

    return true;
  }

  /**
   * Views
   */
  _getCategories() {
    return this.state.sortMode ? this._getCategoriesSortableView() : this._getCategoryWithPrizesView();
  }

  _getCategoryWithPrizesView() {
    const currentStore = this._getCurrentStore();

    if (!currentStore.length) return this._getEmptyCategoryListView();

    return currentStore.map((cat) => (
      <Category
        {...cat}
        key={cat.id || cat.uuid}
        canDelete={this._canDeleteCategory(cat)}
        errors={this._getErrorsForCategory(cat)}
        onDelete={() => this.deleteCategory(cat)}
        title={this._getTitleForCategory(cat)}
        updateCategoryValue={(key, value, errors) => this.updateCategory({ ...cat, [key]: value }, errors)}
      />
    ));
  }

  _getEmptyCategoryListView() {
    return (
      <div className={styles.category}>
        <div className={`${styles.categoryHeader} ${styles.noBorder}`}>
          <div className={styles.categoryHeaderText}>
            <h4 className={`${typography.h4}`}>Create a category using the menu below</h4>
          </div>
        </div>
      </div>
    );
  }

  _getCategoriesSortableView() {
    return (
      <DragAndDropList
        ItemComponent={SortablePrizeRow}
        classNames={{ container: styles.sortableList }}
        dragEndCallback={this.updateCategoryOrder}
        hasDragHandle={true}
        itemProps={{ getTitleForCategory: (cat) => this._getTitleForCategory(cat) }}
        items={this._getCurrentStore()}
      />
    );
  }

  _getIntroduction() {
    return this.state.useCustomIntro ? this._getCustomIntroView() : this._getDefaultIntroView();
  }

  _getCustomIntroView() {
    return (
      <div className={layout.marginTop15}>
        <BasicFormInput
          element="textarea"
          onChange={(e) => this.setState({ customIntro: e.target.value })}
          placeholder="Write an introduction to the prize section of your contest."
          value={this.state.customIntro}
        />
      </div>
    );
  }

  _getDefaultIntroView() {
    return (
      <div className={layout.marginTop15}>
        <p className={`${typography.bodyS} ${layout.marginBottom5}`}>Default introduction:</p>
        <p className={typography.bodyS}>{getDefaultIntroduction(this._getPrizeDataForIntroduction())}</p>
      </div>
    );
  }

  _getSubTextForCategoriesHeader() {
    if (this.state.mode !== CATEGORY_PRIZES_MODE) return null;

    return (
      <p className={`${typography.bodyS} ${layout.marginBottom10}`}>{CATEGORIES_PRIZES_MODE_SUB_COPY}</p>
    );
  }

  render() {
    return (
      <div className={styles.root}>
        <div className={`${styles.prizesContainer}`}>
          <div>
            <div className={styles.sectionHeader}>
              <h2 className={`${typography.h2} ${layout.marginBottom10}`}>Introduction</h2>
              <p className={`${typography.bodyS} ${layout.marginBottom10}`}>
                This is an introduction to the prize section in your contest brief.
              </p>
            </div>

            <div className={layout.marginBottom45}>
              <Checkbox
                isChecked={this.state.useCustomIntro}
                label="Create a custom introduction"
                onChange={(useCustomIntro) => this.setState({ useCustomIntro })}
                value={this.state.useCustomIntro}
              />
              {this._getIntroduction()}
            </div>
          </div>

          <div>
            <div>
              <div className={styles.sectionHeader}>
                <h2 className={`${typography.h2} ${layout.marginBottom10}`}>Group type</h2>
              </div>
              <RadioGroup
                buttons={GROUP_TYPE_RADIO_BUTTONS}
                name="undefined" // jQuery will reset the inputs values otherwise.
                onChange={(e) => this._toggleMode(e)}
                value={this.state.mode}
              />
            </div>

            <div id={CATEGORY_LIST_ID}>
              <div className={`${styles.sectionHeader} ${layout.marginBottom0}`}>
                <h2 className={`${typography.h2} ${layout.marginBottom10}`}>Categories</h2>
                {this._getSubTextForCategoriesHeader()}
                <div>
                  {this.state.sortMode
                  && (
                    <p className={`${typography.bodyS} ${layout.marginBottom10}`}>
                      <span>Sort mode is on. Drag and drop a category to reposition. </span>
                      <span className={typography.linkBlue} onClick={() => this.setState({ sortMode: false })}>Turn sort mode off.</span>
                    </p>
                  )}
                </div>
              </div>
              {this._getCategories()}
            </div>

            <Menu
              addCategory={this.addCategory}
              categories={this._getCurrentStore()}
              mode={this.state.mode}
              onSortModeCheckboxChange={this.onSortModeCheckboxChange}
              sortMode={this.state.sortMode}
            />
          </div>
        </div>

        {this.state.mode === CATEGORY_PRIZES_MODE
        && (
          <EnterableChecklist
            categories={this._getEnterableCategories()}
            updateCategory={this.updateCategory}
          />
        )}
      </div>
    );
  }
}

ChallengePrizeEditor.propTypes = {
  challenge: PropTypes.shape({
    prize_categories: PropTypes.arrayOf(PropTypes.shape({
      description: PropTypes.string,
      id: PropTypes.number.isRequired,
      is_enterable: PropTypes.bool.isRequired,
      is_ranked: PropTypes.bool,
      is_runner_up: PropTypes.bool,
      name: PropTypes.string,
      position: PropTypes.number,
      prizes: PropTypes.arrayOf(PropTypes.shape({
        cash_value: PropTypes.number,
        description: PropTypes.string,
        id: PropTypes.number.isRequired,
        image: PropTypes.shape({
          id: PropTypes.number.isRequired,
          url: PropTypes.string.isRequired,
        }),
        link: PropTypes.string,
        name: PropTypes.string.isRequired,
        position: PropTypes.number,
        quantity: PropTypes.number,
        requires_shipping: PropTypes.bool,
      })),
    })),
    id: PropTypes.number.isRequired,
    prizes_intro: PropTypes.string,
  }).isRequired,
};

ChallengePrizeEditor.defaultProps = { challenge: null };

export default ChallengePrizeEditor;
