import { mapifyStringQuery } from '../../utility/converters';

export const isWindowDefined = (typeof window !== 'undefined');

function getValue(service) {
  return window.location[service];
}

function hasValue(service) {
  /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
  /* eslint-disable-next-line no-prototype-builtins */
  return window.location.hasOwnProperty(service) && window.location[service].length > 0;
}

function factory(service) {
  return {
    get: () => getValue(service),
    hasValue: () => hasValue(service),
  };
}

export function documentExists() {
  return typeof document !== 'undefined';
}

export function getNavigator() {
  return typeof navigator === 'undefined' ? null : navigator;
}

export const windowLocationSearch = (function() {
  return factory('search');
}());

export const windowLocationHash = (function() {
  return factory('hash');
}());

export const windowLocationHref = (function() {
  return {
    ...factory('href'),
    removeHash: () => hasValue('hash') ? getValue('href').split('#')[0] : getValue('href'),
  };
}());

export const windowLocationHostname = (function() {
  return factory('hostname');
}());

export const windowLocationOrigin = (function() {
  return factory('origin');
}());

export const windowLocationPathname = (function() {
  return factory('pathname');
}());

export const windowLocationPort = (function() {
  return factory('port');
}());

export const windowLocationProtocol = (function() {
  return factory('protocol');
}());

// https://stackoverflow.com/a/44077777
export function documentHeight() {
  return document.documentElement.scrollHeight;
}

export function doesBodyHaveUserId() {
  if (!documentExists() || !document.body) return false;

  return document.body.hasAttribute('data-user-signed-in');
}

export function getUserIdFromBody() {
  if (!doesBodyHaveUserId()) return null;

  return document.body.getAttribute('data-user-signed-in');
}

export function doesWindowLocationHaveParam(param) {
  if (windowLocationSearch.hasValue()) {
    const paramMap = mapifyStringQuery(windowLocationSearch.get().slice(1));
    /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
    /* eslint-disable-next-line no-prototype-builtins */
    return paramMap.hasOwnProperty(param);
  } else {
    return false;
  }
}

export function doesWindowExistWithProperty(property) {
  return !!(window && window[property]);
}

export function getDocumentProperty(prop) {
  if (!documentExists() || !document[prop]) return;

  return document[prop];
}

// Use only when falling back to an empty object is okay.
export function getWindow() {
  return isWindowDefined ? window : {};
}

export function getWindowProperty(prop) {
  if (!window || !window[prop]) return;

  return window[prop];
}

export function getDocumentFunction(name) {
  if (!documentExists() || (typeof document[name] !== 'function')) return;

  return document[name].bind(document);
}

export function setDocumentCookie(value) {
  document.cookie = value;
  document.cookie = 'path=/';
}

export function reloadDocumentLocation() {
  document.location.reload();
}

export function windowLocationRedirect(url) {
  window.location = url;
}

export function windowLocationReload(forceGet) {
  window.location.reload(forceGet);
}

export function windowScrollTo(x, y) {
  window.scrollTo(x, y);
}

export function windowDPR() {
  return window.devicePixelRatio || 1;
}

export function windowInnerWidth() {
  return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
}

export function windowInnerHeight() {
  return window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
}

export function windowPageYOffset() {
  return window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
}

export function windowPageXOffset() {
  return window.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft;
}

export function windowSetTimeout(cb, delayTime) {
  return window.setTimeout(cb, delayTime);
}
