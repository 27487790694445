import React from 'react';

const PlayButton = ({ className, fill = '#FFFFFF', height = 56, width = 56 }) => (
  <svg className={className} fill={fill} height={height} version="1.1" viewBox="0 0 42 42" width={width} xmlns="http://www.w3.org/2000/svg">
    <g fillRule="nonzero">
      <circle cx="21" cy="21" fill="none" r="20"></circle>
      <path d="M21 41.17c-11.6 0-21-9.22-21-20.58C0 9.22 9.4 0 21 0s21 9.22 21 20.59c0 11.36-9.4 20.58-21 20.58zm0-1.95c10.5 0 19-8.34 19-18.63 0-10.3-8.5-18.64-19-18.64S2 10.3 2 20.6c0 10.29 8.5 18.63 19 18.63z" />
      <path d="M29.52 21.5l-12.08 5.92a1 1 0 0 1-1.44-.9V14.67a1 1 0 0 1 1.44-.9l12.08 5.93a1 1 0 0 1 0 1.8z" />
    </g>
  </svg>
);

export default PlayButton;
