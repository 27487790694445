import React, { Component } from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash.throttle';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import PopoverArrow from './PopoverArrow';

import { getPosition } from './getPosition';
import { isDomElement } from '../../../utility/document';

import colors from '../../../styles/global_ui/colors.css';
import dialogTransitions from '../Dialog/transitions.css';
import styles from './popover.css';

function getArrowDirection(popoverPosition) {
  return {
    right: 'left',
    left: 'right',
    top: 'bottom',
    bottom: 'top',
  }[popoverPosition];
}

const defaultState = {
  adjustments: { targetOffset: 20 },
  arrowDirection: getArrowDirection('bottom'),
  arrowStyles: {},
  bodyStyles: {},
  component: null,
  position: 'bottom',
  show: false,
  uuid: null,
};

class GlobalPopover extends Component {
  constructor(props) {
    super(props);

    this.state = defaultState;

    this.getPopupPosition = this.getPopupPosition.bind(this);
    this.handleGlobalCloseEvent = this.handleGlobalCloseEvent.bind(this);
    this.handleGlobalOpenEvent = this.handleGlobalOpenEvent.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);

    // Ref
    this.popover;
    this._isMounted = false;
    this._popoverHovered = false;
    this._target = null;
  }

  componentDidMount() {
    this.throttledScroll = throttle(this.handleScroll, 1000).bind(this);
    window.addEventListener('scroll', this.throttledScroll);
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('open:GlobalPopover', this.handleGlobalOpenEvent);
    window.addEventListener('close:GlobalPopover', this.handleGlobalCloseEvent);
    this._isMounted = true;
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.throttledScroll);
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('open:GlobalPopover', this.handleGlobalOpenEvent);
    window.removeEventListener('close:GlobalPopover', this.handleGlobalCloseEvent);
    this._isMounted = false;
    this._target = null;
  }

  getPopupPosition() {
    if (!this.popover || this._target === null || !isDomElement(this._target)) return;

    const { adjustedArrowDirection, arrowStyles, bodyStyles } = getPosition(
      this.state.position,
      this._target.getBoundingClientRect(),
      this.popover.getBoundingClientRect(),
      this.state.adjustments,
    );
    const arrowDirection = adjustedArrowDirection || getArrowDirection(this.state.position);

    this.setState({ arrowDirection, arrowStyles, bodyStyles });
  }

  handleGlobalOpenEvent(e) {
    const { adjustments, component, position, target, uuid } = e.detail;

    if (isDomElement(target) && this._shouldRender()) {
      // NOTE: target doesn't like to be stuffed in state for some React reason.
      this._target = target;
      this.setState({
        adjustments,
        component,
        position,
        uuid,
        arrowDirection: getArrowDirection(position),
        show: true,
      }, () => this._waitForRef());
    }
  }

  _shouldRender() {
    return window.innerWidth > this.props.hideAtScreenWidth;
  }

  /**
   * We need the refs positional data which may take time, this will make sure the node is mounted.
   */
  _waitForRef(loop = 0) {
    if (!this.popover && loop <= 100) {
      setTimeout(() => this._waitForRef(loop + 10), 10);
    } else {
      this.getPopupPosition();
    }
  }

  handleGlobalCloseEvent(e) {
    this._delayedDismiss();
  }

  _delayedDismiss() {
    setTimeout(() => {
      if (this._isMounted && this._target !== null && this._popoverHovered === false) {
        this.setState({ ...defaultState, position: this.state.position });
        this._target = null;
      }
    }, 100);
  }

  handleResize() {
    this.getPopupPosition();
  }

  handleScroll() {
    if (this._target !== null) {
      this._delayedDismiss();
    }
  }

  onMouseEnter(e) {
    this._popoverHovered = true;
  }

  onMouseLeave() {
    this._popoverHovered = false;
    this._delayedDismiss();
  }

  render() {
    return (
      <TransitionGroup>
        {this.state.show
        && (
          <CSSTransition classNames={dialogTransitions} timeout={250}>
            <div
              key={this.state.uuid}
              ref={(el) => this.popover = el}
              className={styles.root}
              onMouseEnter={this.onMouseEnter}
              onMouseLeave={this.onMouseLeave}
              style={this.state.bodyStyles}
            >
              {this.state.component}
              {this.state.component
              && (
                <PopoverArrow
                  borderColor={colors['v-asphalt']}
                  color="white"
                  direction={this.state.arrowDirection}
                  style={this.state.arrowStyles}
                />
              )}
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    );
  }
}

GlobalPopover.propTypes = { hideAtScreenWidth: PropTypes.number };

GlobalPopover.defaultProps = { hideAtScreenWidth: 1100 };

export default GlobalPopover;
