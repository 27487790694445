import PropTypes from 'prop-types';
import React from 'react';

import BookmarkButton from '../../buttons/bookmark_button';
import LazyImage from '../../reusable_components/LazyImage';
import ProjectCardStats from './ProjectCardStats';
import ProjectCardTeam from './ProjectCardTeam';
import ProjectLink from './ProjectLink';

import { normalizeProject } from './helpers';

import cardStyles from '../cards.css';
import styles from './project_card.css';
import typography from '../../../styles/global_ui/typography.css';
import utilStyles from '../../../styles/global_ui/util.css';

const NON_BOOKMARKABLE = ['PRIVATE'];

const _getCustomIcon = (project, usePortal) => (
  NON_BOOKMARKABLE.includes(project.published_state) ? null : <BookmarkButton projectId={project.id} usePortal={usePortal} />
);

const ProjectCard = (props) => {
  const project = normalizeProject(props.project);
  const {
    cardBorder,
    classList,
    config,
    customIcon,
    enableOverlay,
    imageProps,
    itemIndex,
    location,
    maxWidth,
    overrideTracking,
    toggleRespect,
    usePortal,
  } = props;

  return (
    <div className={classList.wrapper || cardStyles.wrapper}>
      <div className={`${styles.card} ${cardStyles.card} ${cardBorder ? utilStyles.border : ''}`}>
        <ProjectLink
          className={styles.imageContainer}
          itemIndex={itemIndex}
          location={location}
          overrideTracking={overrideTracking}
          project={project}
        >
          <div className={styles.itemImage}>
            <LazyImage
              alt={project.name}
              className={styles.lazyImage}
              ratio="4:3"
              setStyle={false}
              src={project.cover_image_url}
              title={project.name}
              width={maxWidth}
              {...imageProps}
            />
            {project.contest_winner
            && <div className={`${typography.h5} ${cardStyles.corner}`}>Winner</div>}
          </div>
          {enableOverlay
          && (
            <div className={styles.overlay}>
              <span className={typography.bodyS}>{project.one_liner}</span>
            </div>
          )}
        </ProjectLink>
        <div className={cardStyles.body}>
          <ProjectLink
            className={`${styles.title} ${typography.bodyM} ${typography.linkCharcoal}`}
            itemIndex={itemIndex}
            location={location}
            overrideTracking={overrideTracking}
            project={project}
          >
            {project.name}
          </ProjectLink>
          <div className={cardStyles.spacer}></div>
          <div>
            <ProjectCardTeam project={project} />
          </div>
        </div>
        <ProjectCardStats
          className={styles.moreInfo}
          controlledRespects={config.controlledRespects || false}
          customIcon={customIcon}
          project={project}
          toggleRespect={toggleRespect}
          usePortal={usePortal}
        />
      </div>
    </div>
  );
};

ProjectCard.propTypes = {
  cardBorder: PropTypes.bool,
  classList: PropTypes.shape({ wrapper: PropTypes.string }),
  config: PropTypes.shape({ controlledRespects: PropTypes.bool }), // Do we want a parent component to control the respect counter.
  customIcon: PropTypes.func, // render prop; goes in the space on the bottom-right corner
  enableOverlay: PropTypes.bool,
  imageProps: PropTypes.object,
  itemIndex: PropTypes.number,
  location: PropTypes.string,
  maxWidth: PropTypes.number,
  overrideTracking: PropTypes.func,
  project: PropTypes.shape({
    content_type: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    cover_image_url: PropTypes.string,
    difficulty: PropTypes.string,
    edit_url: PropTypes.string,
    guest_name: PropTypes.string,
    id: PropTypes.number,
    hid: PropTypes.string,
    name: PropTypes.string,
    one_liner: PropTypes.string,
    published_state: PropTypes.string,
    respects: PropTypes.number,
    stats: PropTypes.object,
    team: PropTypes.object,
    url: PropTypes.string,
  }).isRequired,
  toggleRespect: PropTypes.func,
  usePortal: PropTypes.bool,
};

ProjectCard.defaultProps = {
  cardBorder: false,
  classList: {},
  config: { controlledRespects: false },
  customIcon: _getCustomIcon,
  enableOverlay: true,
  imageProps: {},
  itemIndex: null,
  location: null,
  maxWidth: 350,
  overrideTracking: null,
  toggleRespect: () => {},
  usePortal: false,
};

export default ProjectCard;
