import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Comment from '../comment';
import CommentEditor from '../editor';
import LazyComponent from '../../wrappers/lazy_component';

import { graphMutate } from '../../../requests/graphql';
import currentUserService from '../../../services/current_user';
import errorHandler from '../../../services/error_handler';
import keenService from '../../../services/keen/main';
import trackRelation from '../../../services/keen/trackRelation';
import { getInObj } from '../../../utility/accessors';
import { summonLoginPanel } from '../../../utility/dispatchers.js';
import { objHasPropertyWithValue } from '../../../utility/predicates';
import smoothScroll from '../../utils/smoothScroll';

import { getComments } from '../requests';

import { COMMENT } from '../../../graphql/respects/enum.js';
import { NOOP_HREF } from '../../../constants/links';

const getNoCommentCopy = (locked = false) => (
  locked ? 'There are no comments.' : 'Start the conversation!'
);

// TODO: We can add a "workers" state object to prevent Likes and such from being spammed. Comment.id mapped to a method name, then if we ever
// want to update the UI accordingly we can map to those values as well.
class ProjectComments extends Component {
  constructor(props) {
    super(props);

    this.state = {
      comments: [],
      initialized: false,
      replyBox: { id: null, show: false },
      user: null,
    };

    this.initializeCommentsLaziley = this.initializeCommentsLaziley.bind(this);
    this.createLike = this.createLike.bind(this);
    this.deleteComment = this.deleteComment.bind(this);
    this.deleteLike = this.deleteLike.bind(this);
    this.createComment = this.createComment.bind(this);
    this.updateComment = this.updateComment.bind(this);

    // Refs
    this._commentEditor;
  }

  componentDidMount() {
    this._fetchCurrentUser();
  }

  /**
   * Initializers
   */
  initializeCommentsLaziley() {
    // TODO: Added for backwards compatability. Remove const below when adding getComments ql query. v2 finds comments via hid. ql finds comments via id.
    // Change the mounted component's prop from "projects" to "Project". v2 uses a hacky constantize conversion method.
    const commentableWithHID = { id: this.props.commentable.hid, type: this.props.commentable.type };

    return getComments(commentableWithHID, this.props.cacheVersion)
      .then((comments) => {
        this.setState({
          comments,
          initialized: true,
        });
      })
      .catch((err) => errorHandler('ProjectComments initializeCommentsLaziley: ', err));
  }

  _fetchCurrentUser() {
    return currentUserService.getStoreAsync()
      .then((user) => {
        if (user && Object.keys(user).length > 0) {
          this.setState({ user });
        }
      })
      .catch((err) => errorHandler('ProjectComments _fetchCurrentUser: ', err));
  }

  /**
   * Methods
   */
  createLike(comment) {
    return graphMutate({ t: 'create_respect' }, { id: comment.id, respectable_type: COMMENT })
      .then(() => this._likeResolver(comment, 'create'))
      .catch((err) => errorHandler('ProjectComments createLike', err));
  }

  deleteComment(comment) {
    return graphMutate({ t: 'delete_comment' }, { id: comment.id })
      .then(() => {
        this.setState({ comments: this._deleteCommentFromComments(comment) });
      })
      .catch((err) => errorHandler('ProjectComments deleteComment', err));
  }

  deleteLike(comment) {
    return graphMutate({ t: 'delete_respect' }, { id: comment.id, respectable_type: COMMENT })
      .then(() => this._likeResolver(comment, 'delete'))
      .catch((err) => errorHandler('ProjectComments deleteLike', err));
  }

  createComment(md_body, parentComment = null) {
    return graphMutate({ t: 'create_comment' }, this._getCreateCommentArgs(parentComment, md_body))
      .then(({ comment }) => this._createCommentResolver(this._buildCommentFromServer(comment, parentComment, md_body)))
      .catch((err) => errorHandler('ProjectComments createComment: ', err));
  }

  updateComment(commentFromReact, md_body) {
    return graphMutate({ t: 'update_comment' }, { id: commentFromReact.id, raw_body: md_body })
      .then(({ comment }) => {
        this.setState({ comments: this._updateCommentInComments({ ...commentFromReact, ...comment, md_body }) });
      })
      .catch((err) => errorHandler('ProjectComments updateComment: ', err));
  }

  /**
   * Helpers
   */
  _addCommentToComments(commentToAdd) {
    if (commentToAdd.depth === 0) {
      return this.state.comments.concat({ root: commentToAdd, children: [] });
    }

    return this.state.comments.map((commentGroup) => {
      if (commentGroup.root.id === commentToAdd.parent_id) {
        commentGroup.children = commentGroup.children.concat(commentToAdd);
      }

      return commentGroup;
    });
  }

  _buildCommentFromServer(commentFromServer, parentComment, md_body) {
    const parentId = getInObj(['id'], parentComment);

    return {
      ...commentFromServer,
      children: [],
      deleted: false,
      depth: parentId ? 1 : 0,
      edited_at: null,
      liking_user_ids: [],
      md_body: md_body,
      parent_id: parentId,
      relations: {},
    };
  }

  // TODO: Break this down when testing.
  _deleteCommentFromComments(commentToRemove) {
    return this.state.comments.reduce((acc, commentGroup) => {
      if (commentToRemove.depth === 0 && commentToRemove.id === commentGroup.root.id) {
        // When the commentGroup has children, flag the root as deleted, but perserve the children as a locked thread.
        // Otherwise, we exclude the commentGroup from the results as fully deleted.
        if (commentGroup.children.length > 0) {
          acc = acc.concat({
            root: { ...commentGroup.root, deleted: true },
            children: commentGroup.children,
          });
        }
      } else if (commentToRemove.parent_id === commentGroup.root.id) {
        // When the commentGroups root is still alive, always honor the commentGroup.
        // When the commentGroups root is deleted and this is the last child to be removed, we remove the entire commentGroup.
        if ((commentGroup.root.deleted === false) || (commentGroup.root.deleted === true && commentGroup.children.length > 1)) {
          acc = acc.concat({
            root: commentGroup.root,
            children: commentGroup.children.filter((child) => child.id !== commentToRemove.id),
          });
        }
      } else {
        acc = acc.concat(commentGroup);
      }

      return acc;
    }, []);
  }

  _getCreateCommentArgs(parentComment, md_body) {
    const parentIdObject = parentComment ? { parent_id: parentComment.id } : {};

    return { ...parentIdObject, commentable_hid: this.props.commentable.hid, commentable_id: this.props.commentable.id, commentable_type: 'Project', raw_body: md_body };
  }

  _likeResolver(comment, action) {
    const isCreate = action === 'create';
    this.setState({
      comments: this._updateCommentInComments({
        ...comment,
        liking_user_ids: isCreate ? comment.liking_user_ids.concat(this.state.user.id) : comment.liking_user_ids.filter((id) => id !== this.state.user.id),
      }),
    });
    trackRelation({ key: 'respected_comment_ids', createOrDeleteBool: isCreate, id: comment.id });
  }

  _createCommentResolver(createdComment) {
    keenService.recordEvent({ eventName: 'Posted comment' }, { comment_id: createdComment.id });

    this.setState({
      comments: this._addCommentToComments(createdComment),
      replyBox: { id: null, show: false },
    }, () => this._createCommentResolverCallback(createdComment));
  }

  _createCommentResolverCallback(createdComment) {
    if (createdComment.depth === 0 && this._commentEditor) {
      this._commentEditor.__resetStateHook();
    }
    this._scrollToComment(createdComment);
  }

  _scrollToComment(createdComment) {
    const el = document.getElementById(`comment_${createdComment.id}`);
    if (el) {
      smoothScroll((el.getBoundingClientRect().top + window.pageYOffset) - (window.innerHeight / 2), 500);
    }
  }

  _updateCommentInComments(updatedComment) {
    return this.state.comments.map((commentGroup) => {
      if (updatedComment.depth === 0 && updatedComment.id === commentGroup.root.id) {
        commentGroup.root = updatedComment;
      } else if (updatedComment.parent_id === commentGroup.root.id) {
        commentGroup.children = commentGroup.children.map((child) => child.id === updatedComment.id ? updatedComment : child);
      }

      return commentGroup;
    });
  }

  /**
   * Views
   */
  _getEditorView() {
    if (this.props.newCommentsDisabled) return null;

    if (objHasPropertyWithValue(this.state.user, 'id')) {
      return this.state.user.isConfirmed ? this._getCommentEditorView() : this._getConfirmationView();
    } else {
      return this._getLoginView();
    }
  }

  _getCommentEditorView() {
    return (
      <div className="comments-form">
        <CommentEditor
          ref={(el) => this._commentEditor = el}
          onPost={this.createComment}
          placeholder={this.props.placeholder}
          uiConfig={{ renderCancel: false }}
        />
      </div>
    );
  }

  _getConfirmationView() {
    return (
      <div className="alert alert-warning">
        <p>
          {'Please confirm your email before commenting. Haven\'t received a confirmation email? '}
          <a className="alert-link" href={'/users/confirmation/new'}>
            Resend
          </a>
          . Contact us at help@hackster.io for help.
        </p>
      </div>
    );
  }

  _summonAuth(action) {
    summonLoginPanel({
      detail: {
        redirect_to: window.location.href,
        state: { currentPanel: action, simplified: true },
      },
    });
  }

  _getLoginView() {
    return (
      <p>
        {'Please '}
        <a href={NOOP_HREF} onClick={() => this._summonAuth('login')}>
          log in
        </a>
        {' or '}
        <a href={NOOP_HREF} onClick={() => this._summonAuth('signup')}>
          sign up
        </a>
        {' to comment.'}
      </p>
    );
  }

  _getCommentView({ comment, children = null, parentIsDeleted = false } = {}) {
    return (
      <Comment
        // TODO: refactor, send children as children
        // eslint-disable-next-line react/no-children-prop
        children={children}
        comment={comment}
        createLike={() => this.createLike(comment)}
        currentUser={this.state.user}
        deleteComment={() => this.deleteComment(comment)}
        deleteLike={() => this.deleteLike(comment)}
        newCommentsDisabled={this.props.newCommentsDisabled}
        parentIsDeleted={parentIsDeleted}
        placeholder={this.props.placeholder}
        postCommentReply={this.createComment}
        replyBox={this.state.replyBox}
        toggleReplyBox={(replyBox) => this.setState({ replyBox })}
        updateComment={this.updateComment}
      />
    );
  }

  _getComments() {
    return this.state.initialized ? this._getCommentsView() : this._getCommentsLoaderView();
  }

  _getCommentsView() {
    if (!this.state.comments.length) return this._getNoCommentsView();

    return React.Children.toArray(this.state.comments.map((comment) => (
      this._getCommentView({
        comment: comment.root,
        children: this._getCommentsViewChildren(comment),
      })
    )));
  }

  _getCommentsViewChildren(comment) {
    return React.Children.toArray(comment.children.map((child) => this._getCommentView({
      comment: child,
      parentIsDeleted: comment.root.deleted,
    }),
    ));
  }

  _getNoCommentsView() {
    return (<div className="first-comment">{getNoCommentCopy(this.props.newCommentsDisabled)}</div>);
  }

  _getCommentsLoaderView() {
    // TODO: Remove font-awesome.
    return (<div style={{ marginTop: 20, textAlign: 'center' }}><i className="fa fa-circle-o-notch fa-2x fa-spin"></i></div>);
  }

  render() {
    return (
      <div className="r-comments">
        {this._getEditorView()}

        <LazyComponent onReveal={() => this.initializeCommentsLaziley()}>
          {this._getComments()}
        </LazyComponent>
      </div>
    );
  }
}

ProjectComments.propTypes = {
  cacheVersion: PropTypes.string,
  commentable: PropTypes.shape({
    // TODO: Remove HID, make id required.
    hid: PropTypes.string,
    id: PropTypes.number,
    type: PropTypes.string.isRequired,
  }).isRequired,
  newCommentsDisabled: PropTypes.bool,
  placeholder: PropTypes.string.isRequired,
};

ProjectComments.defaultProps = {
  cacheVersion: '',
  newCommentsDisabled: false,
};

export default ProjectComments;
