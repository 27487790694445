import React from 'react';
import PropTypes from 'prop-types';

import AnchorButton from '../..//buttons/anchor';
import ContestSponsorsList from './helpers/ContestSponsorsList';
import LazyImage from '../../reusable_components/LazyImage';

import { getInObj } from '../../../utility/accessors';

import cardStyles from '../cards.css';
import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';
import styles from './contest_card.css';

/**
 * Helpers
 */
export const getButtonProps = ({ status, url }) => {
  switch (status) {
    case 'in_progress':
    case 'IN_PROGRESS':
      return { href: url, label: 'Participate' };
    case 'judging':
    case 'JUDGING':
      return { href: `${url}/submissions`, label: 'View submissions' };
    case 'judged':
    case 'JUDGED':
      return { href: `${url}#winners`, label: 'View winners' };
    default:
      return { href: url, label: 'View contest' };
  }
};

/**
 * Views
 */
const ButtonView = ({ config, item, onClick }) => {
  const { href, label } = getButtonProps(item);

  return (
    <AnchorButton href={href} onClick={onClick} size={config.btnSize || 'md'}>
      {label}
    </AnchorButton>
  );
};

/**
 * Main Component
 */
const ContestCard = ({ classList, config, item, onClick, sponsorsConfig }) => (
  <div className={`${cardStyles.wrapper} ${classList.wrapper}`}>
    <div className={cardStyles.cardWithBorder}>
      <a href={item.url} onClick={onClick}>
        <LazyImage
          alt={item.name}
          className={styles.lazyImage}
          fit="crop"
          ratio="2:1"
          /* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
          /* eslint-disable-next-line no-prototype-builtins */
          setStyle={config.hasOwnProperty('imageSetStyle') ? config.imageSetStyle : true}
          src={getInObj(['alternate_cover_image_url'], item) || getInObj(['cover_image_url'], item) || getInObj(['cover_image', 'url'], item)}
          width={270}
        />
      </a>

      <div className={styles.contestBody}>
        <a
          className={`${cardStyles.title} ${typography.bodyM} ${typography.linkCharcoal} ${layout.marginTop15} ${layout.marginBottom10}`}
          href={item.url}
          onClick={onClick}
        >
          {item.name}
        </a>

        <ContestSponsorsList sponsors={item.sponsors} sponsorsConfig={sponsorsConfig} />

        <div className={`${cardStyles.spacer} ${classList.spacer}`} />

        <ButtonView config={config} item={item} onClick={onClick} />
      </div>
    </div>
  </div>
);

// NOTE: There are 2 sources feeding the item prop. One from Algolia for the View all list on the home page,
// the other from a graphQL shape for the contests page.
ContestCard.propTypes = {
  classList: PropTypes.shape({
    spacer: PropTypes.string,
    wrapper: PropTypes.string,
  }),
  config: PropTypes.shape({
    btnSize: PropTypes.string,
    imageSetStyle: PropTypes.bool,
  }),
  item: PropTypes.shape({
    alternate_cover_image_url: PropTypes.string, // Home page shapd v2 (data from Algolia)
    cover_image_url: PropTypes.string, // Home page shape v1, is for fallback (data from Algolia)
    cover_image: PropTypes.shape({ // Contest page shape (data from GraphQL)
      url: PropTypes.string,
    }),
    id: PropTypes.number,
    name: PropTypes.string,
    sponsors: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
    })),
    status: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  onClick: PropTypes.func,
  sponsorsConfig: PropTypes.shape({
    limit: PropTypes.number,
    menuPos: PropTypes.shape({
      alignRight: PropTypes.bool,
      alignTop: PropTypes.bool,
    }),
  }),
};

ContestCard.defaultProps = {
  classList: {
    spacer: '',
    wrapper: '',
  },
  config: {
    btnSize: 'sm',
    imageSetStyle: true,
  },
  onClick: () => {},
  sponsorsConfig: {
    limit: 5,
    menuPos: {
      alignRight: false,
      alignTop: true,
    },
  },
};

export default ContestCard;
