import React from 'react';
import PropTypes from 'prop-types';

import Article from './article';
import Sidebar from './sidebar';
import SidebarPlaceholder from './sidebar/SidebarPlaceholder';

import layout from '../../../../styles/global_ui/layout.css';
import utilStyles from '../../../../styles/global_ui/util.css';
import styles from './article_layout.css';

const ArticleLayout = ({ article, isPreview, latestArticles, relatedArticles, signup_cta_image_urls, social_sharing_links, sponsoredArticles }) => (
  <div className={`${layout.fullWidth} ${layout.flex} ${styles.row1}`}>
    <div className={layout.flex1}>
      <Article article={article} isPreview={isPreview} social_sharing_links={social_sharing_links} />
    </div>
    <div className={`${layout.hiddenMedDown} ${layout.marginLeft30} ${layout.marginRight30} ${utilStyles.borderLeft}`} />
    {/* divider */}
    <div className={styles.sidebar}>
      {isPreview
        ? <SidebarPlaceholder />
        : (
          <Sidebar
            article={article}
            latestArticles={latestArticles}
            relatedArticles={relatedArticles}
            signup_cta_image_urls={signup_cta_image_urls}
            sponsoredArticles={sponsoredArticles}
          />
          )}
    </div>
  </div>
);

ArticleLayout.propTypes = {
  article: PropTypes.object.isRequired,
  isPreview: PropTypes.bool,
  latestArticles: PropTypes.array,
  relatedArticles: PropTypes.array,
  signup_cta_image_urls: PropTypes.object,
  social_sharing_links: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    service: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  })),
  sponsoredArticles: PropTypes.array,
};

ArticleLayout.defaultProps = {
  isPreview: false,
  latestArticles: null,
  relatedArticles: null,
  signup_cta_image_urls: null,
  social_sharing_links: [],
  sponsoredArticles: null,
};

export default ArticleLayout;
