import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import AltBookmarkButton from './AltBookmarkButton';
import BookmarkListForm from '../../client/bookmarks/bookmark_list_form';
import Button from '../../client/buttons/base';
import Breadcrumb from '../../client/nav_components/breadcrumb';
import DummyProjectsList from '../../client/projects/projects_list/DummyProjectsList';
import GridList from '../../client/wrappers/grid_list';
import GraphQLPaginatedList from '../../client/wrappers/graph_ql/GraphQLPaginatedList';
import Icon from '../../client/icon';
import Prompt from '../../client/reusable_components/Dialog/Prompt';
import ProjectCard from '../../client/cards/project_card';

import GraphQLProjectsService from '../../services/graphql/projects_service';

import SeoHandler from '../../services/seo_handler';
import bookmarkStore from '../../services/bookmark_store';
import createHistory from '../../client/reusable_components/Router/history';
import errorHandler from '../../services/error_handler';
import seoConfig from './seoConfig';
import { addOrRemoveBookmark, buildBookmarkMessage, getDashboardBookmarksURL, getProjectsCount } from '../../client/bookmarks/helpers';
import { getInObj } from '../../utility/accessors';
import { getErrorHelperView } from '../../client/form_components/templates';
import { graphMutate } from '../../requests/graphql';
import { summonGlobalMessenger } from '../../utility/dispatchers';

import keenService from '../../services/keen/main';
import { getDeleteBookmarkListArgs, getOpenedBookmarkMenuArgs } from '../../services/keen/events/eventTypeTemplates';

import { GENERIC_ERROR } from '../../constants/alerts';

import formStyles from '../../styles/global_ui/forms.css';
import headerStyles from '../../client/user/page_header/page_header.css';
import layout from '../../styles/global_ui/layout.css';
import typography from '../../styles/global_ui/typography.css';
import utilStyles from '../../styles/global_ui/util.css';

const ANALYTICS_CONSTANTS = { widget_src: 'list_page' };

const Placeholder = ({ imageURLs }) => (
  <div className={`${typography.textCenter} ${layout.marginTop15}`}>
    <img
      src={imageURLs.emptyList.x1}
      srcSet={`${imageURLs.emptyList.x1} 1x, ${imageURLs.emptyList.x2} 2x`}
    />
    <div className={`${typography.bodyM} ${layout.marginTop30}`}>This bookmark list has no projects yet!</div>
  </div>
);

class BookmarkListPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentHistoryData: null,
      initialized: false,
      list: props.list,
      prompt: {
        hasError: false,
        isBusy: false,
        open: false,
      },
      showEditForm: false,
    };

    this.history = createHistory(props.pathHelpers.basePath);
    this.qlService = new GraphQLProjectsService({ history: this.history });
    this.seoHandler = new SeoHandler({ config: seoConfig });

    this.deleteList = this.deleteList.bind(this);
    this.dismissEditForm = this.dismissEditForm.bind(this);
    this.dismissPrompt = this.dismissPrompt.bind(this);
    this.getPaginatorHook = this.getPaginatorHook.bind(this);
    this.handleBookmarkChange = this.handleBookmarkChange.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.handleQuery = this.handleQuery.bind(this);
    this.handleRemoveClick = this.handleRemoveClick.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.removeBookmark = this.removeBookmark.bind(this);
    this.renderBookmarkButton = this.renderBookmarkButton.bind(this);
    this.summonEditForm = this.summonEditForm.bind(this);

    this.requestPage; // attaches to graphqlpaginatedlist hook
    this._currentPage;
  }

  /**
   * Lifecycle
   */
  componentDidMount() {
    this.unlisten = this.history.listen(this.handleLocationChange);
    this.updateSub = bookmarkStore.getChannel().subscribe('bookmark.changed', this.handleBookmarkChange);
    this._updateSEOData();
  }

  componentWillUnmount() {
    if (typeof this.unlisten === 'function') this.unlisten();
    if (this.updateSub && typeof this.updateSub.unsubscribe === 'function') this.updateSub.unsubscribe();
  }

  /**
   * Methods
   */
  deleteList(e) {
    this._promptBusy();

    return graphMutate({ t: 'delete_bookmark_list' }, { id: this.state.list.id })
      .then(({ list }) => {
        // Note: if this ever changes so that it does not redirect immediately, uncomment below
        // bookmarkStore.removeList(this.state.list.id);
        keenService.reportEventWithDelayedRedirectWithObj(
          getDeleteBookmarkListArgs({ id: list.id, ...ANALYTICS_CONSTANTS }),
          getDashboardBookmarksURL(),
          e,
        );
      })
      .catch((err) => {
        this._promptError();
        errorHandler('BookmarkListPage deleteList', err);
      });
  }

  dismissEditForm() {
    this.setState({ showEditForm: false });
  }

  dismissPrompt() {
    this.setState({ prompt: { hasError: false, isBusy: false, open: false } });
  }

  getPaginatorHook(fn) {
    this.requestPage = fn;
  }

  handleBookmarkChange({ addOrRemoveBool, listId, projectId }) {
    if (!addOrRemoveBool && listId === this.state.list.id) {
      this.setState({
        list: {
          ...this.state.list,
          projects_count: this.state.list.projects_count - 1,
        },
      });

      // Force GraphQLPaginatedList to refetch updated data
      if (typeof this.requestPage === 'function' && this._currentPage) {
        this.requestPage(this._currentPage);
      }
    }
  }

  handleDeleteClick() {
    this.setState({
      prompt: {
        action: 'Delete',
        body: 'Once you delete this item, it cannot be undone.',
        hasError: false,
        isBusy: false,
        okay: this.deleteList,
        open: true,
        title: 'Are you sure you want to delete this bookmark list?',
      },
    });
  }

  handleLocationChange(pathData, action) {
    const historyData = { ...pathData, action };
    this.setState({ currentHistoryData: historyData });
    this._updateSEOData();
  }

  handleQuery(query) {
    const currentPage = getInObj(['metadata', 'current_page'], query);
    if (currentPage !== this._currentPage) this._currentPage = currentPage;
  }

  handleRemoveClick(projectId) {
    this.setState({
      prompt: {
        open: true,
        hasError: false,
        isBusy: false,
        title: 'Are you sure you want to remove this project?',
        action: 'Remove',
        body: null,
        okay: () => this.removeBookmark(projectId),
      },
    });
  }

  handleUpdate(newList) {
    const list = { ...this.state.list, ...newList };
    bookmarkStore.updateList(list);

    this.setState({
      list,
      showEditForm: false,
    });
  }

  removeBookmark(projectId) {
    this._promptBusy();

    return addOrRemoveBookmark({ addOrRemoveBool: false, list: this.state.list, projectId }, ANALYTICS_CONSTANTS)
      .then((lists) => {
        this.dismissPrompt();
        summonGlobalMessenger({ msg: buildBookmarkMessage(false, this.state.list.name) });
      })
      .catch((err) => {
        this._promptError();
        errorHandler('BookmarkListPage removeBookmark', err);
      });
  }

  renderBookmarkButton(project) {
    return (
      <AltBookmarkButton
        analytics={ANALYTICS_CONSTANTS}
        handleRemove={this.handleRemoveClick}
        list={this.state.list}
        onSummonMenu={(data) => this._handleOpenMenuAnalytics(data)}
        projectId={project.id}
      />
    );
  }

  summonEditForm() {
    this.setState({ showEditForm: true });
  }

  /**
   * Helpers
   */
  _handleOpenMenuAnalytics({ project_id }) {
    keenService.reportEventWithObj(getOpenedBookmarkMenuArgs({
      project_id,
      ...ANALYTICS_CONSTANTS,
    }));
  }

  _promptBusy() {
    this.setState({ prompt: { ...this.state.prompt, hasError: false, isBusy: true } });
  }

  _promptError() {
    this.setState({ prompt: { ...this.state.prompt, hasError: true, isBusy: false } });
  }

  _updateSEOData() {
    this.seoHandler.reportView({ path: '/', data: this.state.list });
  }

  /**
   * Views
   */
  _getEditForm(list) {
    return (
      <div className={formStyles.container}>
        <BookmarkListForm
          analytics={ANALYTICS_CONSTANTS}
          clearOnSubmit={false}
          initValues={{ id: list.id, name: list.name, private: list.private }}
          mode="update"
          onCancelClick={this.dismissEditForm}
          onDeleteClick={this.handleDeleteClick}
          onSubmit={this.handleUpdate}
        />
      </div>
    );
  }

  _getHeader(list) {
    return (
      <div className={headerStyles.headerInner}>
        <div className={layout.flex10Auto}>
          <h2 className={typography.h2}>
            {list.name}
            {list.private
            && <Icon className={`${layout.marginLeft5} ${layout.marginTop5}`} name="locked" size={16} />}
          </h2>
          <div className={typography.bodyM}>
            {getProjectsCount(list.projects_count)}
          </div>
        </div>
        <Button
          className={typography.whitespaceNowrap}
          colorStyle="tertiary"
          onClick={this.summonEditForm}
          size="sm"
        >
          Edit
        </Button>
      </div>
    );
  }

  render() {
    const { list, showEditForm } = this.state;

    return (
      <Fragment>
        <div className={layout.container}>
          <div className={layout.wrapper1170}>
            <Breadcrumb
              color="Pebble"
              href={getDashboardBookmarksURL()}
              size="S"
              text="Back to my bookmarks"
            />
            {showEditForm ? this._getEditForm(list) : this._getHeader(list)}
          </div>
        </div>
        <div className={`${layout.container} ${utilStyles.borderTop}`}>
          <div className={layout.wrapper1170}>
            <GraphQLPaginatedList
              currentHistoryData={this.state.currentHistoryData}
              getPaginatorHook={this.getPaginatorHook}
              graphQLArguments={{ list_id: this.props.list.id }}
              graphQLService={this.qlService}
              listComponent={GridList}
              listProps={{
                ItemComponent: ProjectCard,
                itemKey: 'project',
                itemProps: {
                  cardBorder: true,
                  customIcon: this.renderBookmarkButton,
                },
                placeholder: <Placeholder imageURLs={this.props.imageURLs} />,
              }}
              loaderComponent={DummyProjectsList}
              propagateQuery={this.handleQuery}
            />
          </div>
        </div>
        <Prompt
          action={this.state.prompt.action}
          actionColor="danger"
          body={this.state.prompt.body}
          dismiss={this.dismissPrompt}
          isBusy={this.state.prompt.isBusy}
          message={this.state.prompt.hasError ? getErrorHelperView(GENERIC_ERROR) : null}
          okay={this.state.prompt.okay}
          open={this.state.prompt.open}
          title={this.state.prompt.title}
        />
      </Fragment>
    );
  }
}

BookmarkListPage.propTypes = {
  imageURLs: PropTypes.shape({
    emptyList: PropTypes.shape({
      x1: PropTypes.string.isRequired,
      x2: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  list: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    private: PropTypes.bool.isRequired,
    projects_count: PropTypes.number,
  }).isRequired,
  pathHelpers: PropTypes.shape({
    basePath: PropTypes.string.isRequired,
    fullPath: PropTypes.string.isRequired,
    rootPath: PropTypes.string.isRequired,
  }).isRequired,
};

export default BookmarkListPage;
