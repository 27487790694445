import React from 'react';
import { getCategoryEnum } from '../../../graphql/feed_posts/enums';
import CategoryBadgeLabel from '../../../client/discussion/templates/CategoryBadgeLabel';

const DEFAULT_CATEGORY_CONFIG = [
  {
    colorClass: 'categoryGreenBG',
    enum: getCategoryEnum('Announcements'),
    path: getCategoryEnum('Announcements').toLowerCase(),
    text: 'Contest announcements from the admin team',
    title: 'Announcements',
  },
  {
    colorClass: 'categoryYellowBG',
    enum: getCategoryEnum('General'),
    path: getCategoryEnum('General').toLowerCase(),
    text: 'Submission guidelines, deadlines and other general questions',
    title: 'General',
  },
  {
    colorClass: 'categoryBlueBG',
    enum: getCategoryEnum('Free hardware'),
    path: getCategoryEnum('Free hardware').toLowerCase(),
    text: 'Questions and updates on selection and distribution of free HW',
    title: 'Free hardware',
  },
  {
    colorClass: 'categoryOrangeBG',
    enum: getCategoryEnum('Technical help'),
    path: getCategoryEnum('Technical help').toLowerCase(),
    text: 'Getting started, hardware troubleshooting and project help',
    title: 'Technical help',
  },
];

const getCategoriesWithoutFreeHardware = () => DEFAULT_CATEGORY_CONFIG.filter((c) => c.enum !== getCategoryEnum('Free hardware'));

const getCategoriesForSelect = (categoryConfigs, labelFn = null) => categoryConfigs.map((config) => ({
  label: (labelFn && typeof labelFn === 'function') ? labelFn(config) : config.title,
  labelText: config.title,
  value: config.enum,
}));

export const createCategoryConfigs = (challenge) => {
  const CATEGORY_CONFIGS = challenge.activate_free_hardware ? DEFAULT_CATEGORY_CONFIG : getCategoriesWithoutFreeHardware();

  return {
    CATEGORY_CONFIGS: CATEGORY_CONFIGS,
    SELECT_CATEGORIES: getCategoriesForSelect(CATEGORY_CONFIGS, (categoryConfig) => (<CategoryBadgeLabel categoryConfig={categoryConfig} />),
    ),
  };
};
