import React from 'react';
import PropTypes from 'prop-types';

import ExtendedView from './ExtendedView';
import Pencil from '../../../client/reusable_components/icons/Pencil';
import Popup from '../../../client/reusable_components/Popup';
import { round } from '../../../utility/formatters';
import { UserType } from './types';

import style from './style.css';
import button from '../../../styles/global_ui/buttons.css';
import typography from '../../../styles/global_ui/typography.css';

const c = {
  row: ({ isDeleted }) => [style.row, isDeleted ? style.rowDeleted : ''].join(' '),
  actionCell: `${style.actionCell}`,
  deleteLabel: `${style.deleteLabel}`,
  editLink: `${typography.linkBlue}`,
  pencilIcon: `${style.pencilIcon}`,
};

const AdminUserModerationRow = ({ userRank }) => (
  <tr className={c.row({ isDeleted: userRank.is_deleted_spam })}>
    <td className={style.control}>
      <label className={c.deleteLabel}>
        <input className={c.deleteInput} disabled={userRank.is_deleted_spam} name={userRank.id} type="checkbox" value="delete" />
      </label>
    </td>
    <td>
      <a href={`/${userRank.user_name}`} rel="noreferrer" target="_blank">{userRank.id}</a>
    </td>
    <td>{userRank.user_name}</td>
    <td>{userRank.created_date}</td>
    <td>{userRank.email}</td>
    <td>{userRank.website && <a href={userRank.website} rel="noreferrer" target="_blank">{userRank.website}</a>}</td>
    <td>{userRank.bio}</td>
    <td>{round(userRank.rank, 1)}</td>
    <td width="120">
      <div className={style.inlineCell}>
        <Popup
          align="start"
          classList={{ trigger: `${button.xs} ${button.outlineBlack}` }}
          side="left"
          trigger={<span>More info</span>}
        >
          <ExtendedView userRank={userRank} />
        </Popup>
        <a
          className={c.editLink}
          href={`/admin/users/${userRank.id}/edit`}
          rel="noreferrer"
          target="_blank"
        >
          <Pencil className={c.pencilIcon} />
        </a>
      </div>
    </td>
  </tr>
);

AdminUserModerationRow.propTypes = { userRank: PropTypes.shape(UserType).isRequired };

export default AdminUserModerationRow;
