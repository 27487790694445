import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import request from 'superagent';

import OAuth from '../../../services/oauth';
import Button from '../../buttons/base';
import SelectableAddress from '../views/SelectableAddress';

import layout from '../../../styles/global_ui/layout.css';
import formStyles from '../../../styles/global_ui/forms.css';
import typography from '../../../styles/global_ui/typography.css';

const AddressBookSelectView = ({ activeAddress, addNewAddress, addressId, addresses, isBusy, editAddress, makeDefault, selectActive, ship }) => {
  const [selected, setSelected] = useState(activeAddress);
  const [updatedAddresses, setUpdatedAddresses] = useState(addresses);

  const deleteAddress = useCallback((id) => OAuth.apiRequest(
    request.del(`${OAuth.getApiPath()}/private/addresses/${id}`),
    true,
  ).then(() => {
    const newAddressList = updatedAddresses.filter((address) => address.id !== id);
    setUpdatedAddresses(newAddressList);

    if (!newAddressList.some(({ id }) => id === selected.id)) {
      setSelected(newAddressList[0]);
    }
  }), [selected.id, updatedAddresses]);

  const handleChange = useCallback(() => {
    selectActive(selected);
    ship && ship(selected.id);
  }, [selectActive, selected, ship]);

  const handleMakeDefault = useCallback(async (address) => {
    const updated = await makeDefault(address);
    setUpdatedAddresses(updated);
  }, [makeDefault]);

  return (
    <div className={formStyles.container}>
      <h2 className={typography.h2}>Choose an address</h2>
      <div className={layout.flexRowWrap}>
        {updatedAddresses.map((address) => (
          <SelectableAddress
            key={address.id}
            address={address}
            disabledEdit={isBusy}
            disabledRemove={updatedAddresses.length <= 1}
            edit={editAddress}
            isChecked={selected.id === address.id}
            makeDefault={handleMakeDefault}
            onChange={(address) => setSelected(address)}
            remove={deleteAddress}
          />
        ))}
      </div>

      <div className={layout.marginTop45}>
        <Button
          colorStyle="secondary"
          disabled={updatedAddresses.length === 0}
          onClick={handleChange}
          size="md"
        >
          Use this address
        </Button>
        <Button
          className={layout.marginLeft15}
          colorStyle="tertiary"
          onClick={addNewAddress}
          size="md"
        >
          Add a new address
        </Button>
      </div>
    </div>
  );
};

AddressBookSelectView.propTypes = {
  activeAddress: PropTypes.object.isRequired, // Same address shape
  addNewAddress: PropTypes.func.isRequired,
  addresses: PropTypes.arrayOf(PropTypes.shape({
    address_line1: PropTypes.string.isRequired,
    address_line2: PropTypes.string,
    city: PropTypes.string.isRequired,
    company: PropTypes.string,
    country: PropTypes.string.isRequired,
    country_iso2: PropTypes.string,
    cpf: PropTypes.string,
    default: PropTypes.boolean,
    id: PropTypes.number.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    zip: PropTypes.string.isRequired,
  })).isRequired,
  editAddress: PropTypes.func.isRequired,
  isBusy: PropTypes.bool,
  makeDefault: PropTypes.func.isRequired,
  selectActive: PropTypes.func.isRequired,
};

AddressBookSelectView.defaultProps = { isBusy: false };

export default AddressBookSelectView;
