import React from 'react';
import PropTypes from 'prop-types';

import AdminBadge from '../templates/AdminBadge';
import PostEditorActions from './PostEditorActions';

import layout from '../../../styles/global_ui/layout.css';
import postCardStyles from '../post_card/post_card.css';
import typography from '../../../styles/global_ui/typography.css';
import styles from './post_editor.css';

const getRecordShapeForAdminBadge = (currentUser) => ({
  user: {
    id: currentUser.id,
    isAdmin: currentUser.role === 'admin',
  },
});

const PostCreator = ({
  categoryConfig,
  categorySelectOpts,
  children,
  currentPath,
  currentUser,
  errors,
  isBusy,
  isEditorFocused,
  markdownBtn,
  onCategorySelect,
  onPost,
  origin,
}) => (
  <div className={styles.postCreatorRoot}>
    <div className={`${styles.postCreatorHeaderAndEditor} ${isEditorFocused ? styles.postCreatorHeaderAndEditorFocused : ''}`}>
      <div className={styles.postCreatorHeader}>
        <img
          alt={currentUser.name}
          className={`${postCardStyles.avatar} ${layout.marginRight10}`}
          src={currentUser.avatar_url}
        />

        {isEditorFocused
        && (
          <div className={`${layout.flexJustifyCenter}`}>
            <div className={layout.flexCenterItems}>
              <a
                className={`${typography.bodyM} ${typography.bold} ${typography.linkCharcoal}`}
                href={currentUser.url}
              >
                {currentUser.name}
              </a>

              <AdminBadge origin={origin} record={getRecordShapeForAdminBadge(currentUser)} />
            </div>
          </div>
        )}
      </div>

      <div className={`${isEditorFocused ? styles.postCreatorEditorWrapperFocused : styles.postCreatorEditorWrapper}`}>
        {children}
      </div>
    </div>

    {isEditorFocused
    && (
      <PostEditorActions
        categoryConfig={categoryConfig}
        categorySelectOpts={categorySelectOpts}
        currentPath={currentPath}
        errors={errors}
        isBusy={isBusy}
        markdownBtn={markdownBtn}
        onCategorySelect={onCategorySelect}
        onPostOrSave={onPost}
      />
    )}
  </div>
);

PostCreator.propTypes = {
  categoryConfig: PropTypes.arrayOf(PropTypes.shape({
    colorClass: PropTypes.string.isRequired,
    enum: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  })).isRequired,
  categorySelectOpts: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    value: PropTypes.string,
  })).isRequired,
  currentPath: PropTypes.string.isRequired,
  currentUser: PropTypes.shape({
    avatar_url: PropTypes.string,
    confirmed: PropTypes.bool,
    id: PropTypes.number,
    name: PropTypes.string,
    role: PropTypes.string,
    url: PropTypes.string,
  }),
  errors: PropTypes.shape({
    category: PropTypes.string,
    content: PropTypes.string,
  }).isRequired,
  isBusy: PropTypes.bool.isRequired,
  isEditorFocused: PropTypes.bool.isRequired,
  markdownBtn: PropTypes.element.isRequired,
  onCategorySelect: PropTypes.func.isRequired,
  onPost: PropTypes.func.isRequired,
  origin: PropTypes.shape({ admin_ids: PropTypes.arrayOf(PropTypes.number) }),
};

PostCreator.defaultProps = { currentUser: null };

export default PostCreator;
