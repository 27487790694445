const ATTACHMENT_TYPES = {
  AlternateCoverImage: 'ALTERNATE_COVER_IMAGE',
  Avatar: 'AVATAR',
  CoverImage: 'COVER_IMAGE',
  Favicon: 'FAVICON',
  FreeHardwareImage: 'FREE_HARDWARE_IMAGE',
  Image: 'IMAGE',
  Logo: 'LOGO',
  MobileCoverImage: 'MOBILE_COVER_IMAGE',
};

export const getAttachmentTypeEnum = (val) => ATTACHMENT_TYPES[val] || null;
