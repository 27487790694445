import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { v4 as uuidv4 } from 'uuid';

import AuthPanel from '../panel';
import Dialog from '../../reusable_components/Dialog';

import { DEFAULT } from '../constants';
import { windowLocationRedirect } from '../../../services/window';

import styles from './auth_dialog.css';

const TRACKING_FORM_NAME = 'signin_dialog';

const whitelistedStates = ['currentPanel', 'simplified'];

const legacyPanelMap = {
  login: 'login',
  signin: 'login',
  signup: 'signup',
};

class AuthDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPanel: legacyPanelMap[props.initialPanel],
      eventDetails: {},
      open: false,
      simplified: false,
      uuid: null,
    };

    this.closeDialog = this.closeDialog.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.openDialog = this.openDialog.bind(this);
  }

  componentDidMount() {
    window.addEventListener(this.props.openEvent, this.openDialog);
  }

  componentWillUnmount() {
    window.removeEventListener(this.props.openEvent, this.openDialog);
  }

  closeDialog() {
    this.setState({ eventDetails: {}, open: false });
  }

  handleSubmit(res = {}) {
    if (typeof this.state.eventDetails.callback === 'function') { // TODO: not sure if this is ever used
      this.state.eventDetails.callback(res.user ? res.user.id : res.id);
    }
  }

  openDialog(e = {}) {
    if (this.props.whitelabelLogin) {
      windowLocationRedirect(this.props.whitelabelLogin);

      return;
    }

    const newState = e.detail && e.detail.state
      ? Object.keys(e.detail.state).reduce((acc, key) => (whitelistedStates.includes(key))
        ? { ...acc, [key]: e.detail.state[key] }
        : acc, {})
      : {};

    const currentPanel = legacyPanelMap[newState.currentPanel] || this.state.currentPanel;

    this.setState({
      ...newState,
      currentPanel,
      eventDetails: e.detail ? e.detail : {},
      open: true,
      uuid: uuidv4(), // force auth panel to reinitialize every time we receive the "open" event
    });
  }

  render() {
    const { currentPanel, eventDetails, open, simplified, uuid } = this.state;
    const source = eventDetails.source || this.props.source;

    return (
      <Dialog dismiss={this.closeDialog} open={open} wrapperClassName={styles.wrapper}>
        {open
        && (
          <AuthPanel
            key={uuid}
            analytics={this.props.analytics}
            confirmationPath={this.props.confirmationPath}
            ctaImgUrls={this.props.ctaImgUrls}
            ctaVersion={this.props.ctaVersion}
            currentPanel={currentPanel}
            forgotPasswordUrl={this.props.forgotPasswordUrl}
            formActions={this.props.formActions}
            location={TRACKING_FORM_NAME}
            omniauthUrls={this.props.omniauthUrls}
            onSubmit={this.handleSubmit}
            pathHelpers={this.props.pathHelpers}
            recaptchaSiteKey={this.props.recaptchaSiteKey}
            redirectPaths={this.props.redirectPaths}
            setCurrentPanel={this.setCurrentPanel}
            simplified={simplified}
            source={source}
            whitelabelName={this.props.whitelabelName}
          />
        )}
      </Dialog>
    );
  }
}

AuthDialog.propTypes = {
  analytics: PropTypes.shape({
    page_type: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    site: PropTypes.string.isRequired,
  }).isRequired,
  confirmationPath: PropTypes.string.isRequired,
  ctaImgUrls: PropTypes.shape({
    '1x': PropTypes.string.isRequired,
    '2x': PropTypes.string.isRequired,
  }).isRequired,
  ctaVersion: PropTypes.string,
  forgotPasswordUrl: PropTypes.string.isRequired,
  formActions: PropTypes.shape({
    login: PropTypes.string,
    signup: PropTypes.string,
  }).isRequired,
  id: PropTypes.string,
  initialPanel: PropTypes.string,
  omniauthUrls: PropTypes.shape({
    facebook: PropTypes.string,
    github: PropTypes.string,
    google_oauth2: PropTypes.string,
    windowslive: PropTypes.string,
  }).isRequired,
  openEvent: PropTypes.string.isRequired,
  pathHelpers: PropTypes.shape({ rootPath: PropTypes.string.isRequired }).isRequired,
  recaptchaSiteKey: PropTypes.string.isRequired,
  redirectPaths: PropTypes.shape({
    login: PropTypes.string.isRequired,
    signup: PropTypes.string.isRequired,
  }).isRequired,
  showCTAConfig: PropTypes.shape({
    login: PropTypes.bool,
    signup: PropTypes.bool,
  }),
  source: PropTypes.string,
  whitelabelLogin: PropTypes.string,
  whitelabelName: PropTypes.string,
};

AuthDialog.defaultProps = {
  ctaVersion: DEFAULT,
  initialPanel: 'login',
  source: '',
  showCTAConfig: {
    login: false,
    signup: true,
  },
  whitelabelLogin: null,
  whitelabelName: null,
};

export default AuthDialog;
