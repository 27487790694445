/* eslint-disable no-prototype-builtins */
/* TODO: consider using hasOwn instead OR Object.prototype.hasOwnProperty.call */
import React from 'react';
import PropTypes from 'prop-types';

import LazyImage from '../../reusable_components/LazyImage';
import VideoOverlay from './VideoOverlay';

import { getInObj } from '../../../utility/accessors';
import { formatNumberLimit4Chars } from '../../../utility/formatters';
import { timestampToPrettyDate, timestampToRelativeTime } from '../../../utility/time';

import cardStyles from '../cards.css';
import typography from '../../../styles/global_ui/typography.css';
import layout from '../../../styles/global_ui/layout.css';
import utilStyles from '../../../styles/global_ui/util.css';
import styles from './video_card.css';

const StatsView = ({ item }) => {
  if (!item.hasOwnProperty('impressions_count') && !item.hasOwnProperty('created_at')) return null;

  return (
    <div className={`${layout.flex} ${layout.marginTop5}`}>
      {Number.isInteger(item.impressions_count)
        && (
          <p className={typography.bodyXS}>
            <span>{`${formatNumberLimit4Chars(item.impressions_count)} views`}</span>
            {item.hasOwnProperty('created_at') && <span className={`${layout.marginLeft5} ${layout.marginRight5}`}>•</span>}
          </p>
        )}
      <p className={typography.bodyXS} title={timestampToPrettyDate(item.created_at)}>{timestampToRelativeTime(item.created_at)}</p>
    </div>
  );
};

const VideoCard = ({ classList, config, i, item, onClick, sectionIndex }) => {
  const hacksterUrl = `/videos/${item.id}`;

  return (
    <div className={`${cardStyles.wrapper} ${classList.wrapper}`}>
      <div className={cardStyles.cardWithBorder}>
        <a aria-label={item.title} className={`${styles.thumbnail}`} href={hacksterUrl} onClick={(e) => onClick(e, item, i, sectionIndex)}>
          <div className={`${utilStyles.absolutePlaceholderParent} ${utilStyles.aspectRatioPadding16_9}`}>
            <LazyImage
              alt={item.title}
              captureScroll={true}
              className={`${styles.radiusTop} ${utilStyles.absolutePlaceholderChild} ${utilStyles.overflowHidden}`}
              fit="crop"
              ratio="16:9"
              setStyle={false}
              src={getInObj(['image', 'url'], item)}
              width={282}
            />
            {config.renderOverlay && <VideoOverlay className={styles.radiusTop} />}
          </div>
        </a>
        <div className={cardStyles.body}>
          <a className={`${typography.bodyM} ${typography.bold} ${typography.link}`} href={hacksterUrl} onClick={(e) => onClick(e, item, i, sectionIndex)}>
            {item.title}
          </a>

          <div className={cardStyles.spacer} />

          <div className={`${layout.marginTop5} ${typography.bodyS}`}>{item.category}</div>

          <StatsView item={item} />
        </div>
      </div>
    </div>
  );
};

VideoCard.propTypes = {
  classList: PropTypes.shape({ wrapper: PropTypes.string }),
  config: PropTypes.shape({ renderOverlay: PropTypes.bool }),
  i: PropTypes.number,
  item: PropTypes.shape({
    category: PropTypes.string.isRequired,
    created_at: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired, // If provided, expects a UTC timestamp string or integer (i.e. timestamp or timestamp.to_i).
    id: PropTypes.number.isRequired,
    image: PropTypes.shape({ url: PropTypes.string.isRequired }),
    impressions_count: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  onClick: PropTypes.func,
  sectionIndex: PropTypes.number,
};

VideoCard.defaultProps = {
  classList: { wrapper: '' },
  config: { renderOverlay: true },
  i: null,
  onClick: () => {},
  sectionIndex: null,
};

export default VideoCard;
