import React from 'react';
import PropTypes from 'prop-types';
import * as Popover from '@radix-ui/react-popover';

import Icon from '../../icon';

import button from '../../../styles/global_ui/buttons';
import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography';
import util from '../../../styles/global_ui/util.css';
import styles from './popup.css';

const Popup = ({
  align = 'center',
  alignOffset,
  anchorRef,
  asModal,
  children,
  classList,
  collisionPadding,
  defaultOpen,
  disabled,
  hasArrow,
  hasClose,
  hideWhenDetached = true,
  id,
  onOpenChange,
  side = 'right',
  sideOffset,
  trigger,
  triggerAria,
  testId,
}) => (
  <Popover.Root defaultOpen={defaultOpen} modal={asModal} onOpenChange={onOpenChange}>
    {trigger && <Popover.Trigger aria-label={triggerAria} className={`${classList?.trigger}`} disabled={disabled}>{trigger}</Popover.Trigger>}
    {anchorRef && <Popover.Anchor virtualRef={anchorRef} />}
    <Popover.Portal>
      <Popover.Content
        align={align}
        alignOffset={alignOffset}
        arrowPadding={hasArrow ? 5 : 0}
        className={`${util.borderRadiusLarge} ${util.border} ${util.boxShadow} ${styles.popoverContent} ${classList?.container}`}
        collisionPadding={collisionPadding}
        data-testid={testId}
        hideWhenDetached={hideWhenDetached}
        id={id}
        side={side}
        sideOffset={sideOffset}
      >
        {hasClose && (
          <Popover.Close aria-label="close" className={`${layout.inlineFlex} ${util.posAbsolute} ${layout.borderNone} ${util.borderRadiusCircle} ${button.unset} ${button.white} ${styles.popoverClose}`}>
            <Icon name="close" size={12} />
          </Popover.Close>
        )}
        {hasArrow && (
          <Popover.Arrow asChild className={`${styles.arrow}`}>
            <svg
              fill="none"
              height="12"
              viewBox="0 0 24 12"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect height="1" width="20" />
              <path
                d="M24 1C18 1 18 11 12 11C6 11 6 0.999999 8.74228e-07 0.999999"
              />
            </svg>
          </Popover.Arrow>
        )}
        {children}
      </Popover.Content>
    </Popover.Portal>
  </Popover.Root>
)
;

export default Popup;

Popup.propTypes = {
  anchorRef: PropTypes.shape({}),
  asModal: PropTypes.bool,
  classList: PropTypes.shape({
    container: PropTypes.string,
    trigger: PropTypes.string,
  }),
  hasArrow: PropTypes.bool,
  hasClose: PropTypes.bool,
  hideWhenDetached: PropTypes.bool,
  trigger: PropTypes.node, //* trigger from Radix renders a button by default. This prop will likely need be a span or icon component.
  triggerAsChild: PropTypes.bool,
};
